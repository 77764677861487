import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft,
  Calendar,
  Download,
  FileText,
  FileSpreadsheet,
  FilePdf,
  AlertCircle,
  ScrollText,
  Table,
  File
} from 'lucide-react';
import apiService from '../../services/api.service';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import StatusBadge from '../../components/shared/StatusBadge';

const AuditExport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    eventTypes: [],
    format: 'csv'
  });

  // Types d'événements disponibles
  const availableEventTypes = [
    { value: 'CREATE', label: 'Création' },
    { value: 'UPDATE', label: 'Modification' },
    { value: 'DELETE', label: 'Suppression' },
    { value: 'ACCESS', label: 'Accès' },
    { value: 'LOGIN', label: 'Connexion' },
    { value: 'LOGOUT', label: 'Déconnexion' },
    { value: 'SYSTEM', label: 'Système' },
    { value: 'BUSINESS', label: 'Business' }
  ];

  // Formats d'export disponibles
  const exportFormats = [
    { 
      value: 'csv', 
      label: 'CSV', 
      icon: Table,
      description: 'Format tableur compatible avec Excel'
    },
    { 
      value: 'json', 
      label: 'JSON', 
      icon: ScrollText,
      description: 'Format structuré pour l\'analyse'
    },
    { 
      value: 'pdf', 
      label: 'PDF', 
      icon: File,
      description: 'Document formaté pour la lecture'
    }
  ];

  const handleEventTypeToggle = (type) => {
    setFormData(prev => ({
      ...prev,
      eventTypes: prev.eventTypes.includes(type)
        ? prev.eventTypes.filter(t => t !== type)
        : [...prev.eventTypes, type]
    }));
  };

  const handleExport = async () => {
    // Validation basique
    if (!formData.startDate || !formData.endDate) {
      setError('Veuillez sélectionner une période');
      return;
    }

    if (formData.eventTypes.length === 0) {
      setError('Veuillez sélectionner au moins un type d\'événement');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      // Pour les dates, on essaie un autre format compatible avec LocalDateTime
      // ISO format sans millisecondes et timezone, qui devrait être plus compatible
      const startDateObj = new Date(formData.startDate);
      const endDateObj = new Date(formData.endDate);
      
      // Format ISO pour les dates (2023-01-01T08:30:00)
      const formatDate = (date) => {
        return date.toISOString().split('.')[0]; // Supprime les millisecondes et le Z
      };
      
      const startDate = formatDate(startDateObj);
      const endDate = formatDate(endDateObj);
      
      // L'URL avec les paramètres pour les types d'événements
      let url = `${apiService.getBaseUrl()}/api/audit/export?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&format=${formData.format}`;
      
      // Ajouter chaque type d'événement comme paramètre séparé
      formData.eventTypes.forEach(type => {
        url += `&eventTypes=${encodeURIComponent(type)}`;
      });
      
      console.log("Requesting export with URL:", url);
      
      const token = localStorage.getItem('auth_token');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': '*/*'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server error response:", errorText);
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
      }
      
      // Récupérer le blob de la réponse
      const blob = await response.blob();
      console.log("Received blob:", blob);
      
      // Créer un lien de téléchargement
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `audit_export_${new Date().toISOString().slice(0, 10)}.${formData.format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);

      setSuccess(true);
    } catch (err) {
      console.error('Export error:', err);
      setError('Erreur lors de l\'export : ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div className="flex items-center space-x-4">
            <Button
              variant="outline"
              icon={ArrowLeft}
              onClick={() => navigate('/audit')}
            >
              Retour
            </Button>
            <div>
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                Export des logs d'audit
              </h2>
              <p className="text-gray-600 dark:text-gray-400">
                Exportez les logs d'audit pour analyse
              </p>
            </div>
          </div>
        </div>

        {error && (
          <div className="mb-6">
            <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          </div>
        )}

        {success && (
          <div className="mb-6">
            <StatusBadge
              status="success"
              customLabel="Export généré avec succès"
              size="lg"
            />
          </div>
        )}
      </Card>

      {/* Formulaire d'export */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Période et types d'événements */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">
            Critères d'export
          </h3>

          <div className="space-y-6">
            {/* Sélection de la période */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Période
              </label>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      type="datetime-local"
                      value={formData.startDate}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        startDate: e.target.value 
                      }))}
                      className="w-full pl-10 p-2 border rounded-lg bg-white dark:bg-gray-700 
                               border-gray-300 dark:border-gray-600"
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Date de début</p>
                </div>
                <div>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      type="datetime-local"
                      value={formData.endDate}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        endDate: e.target.value 
                      }))}
                      className="w-full pl-10 p-2 border rounded-lg bg-white dark:bg-gray-700 
                               border-gray-300 dark:border-gray-600"
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Date de fin</p>
                </div>
              </div>
            </div>

            {/* Types d'événements */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Types d'événements
              </label>
              <div className="grid grid-cols-2 gap-2">
                {availableEventTypes.map(({ value, label }) => (
                  <label 
                    key={value}
                    className="flex items-center p-3 rounded-lg border border-gray-200 
                             dark:border-gray-700 cursor-pointer hover:bg-gray-50 
                             dark:hover:bg-gray-800 transition-colors"
                  >
                    <input
                      type="checkbox"
                      checked={formData.eventTypes.includes(value)}
                      onChange={() => handleEventTypeToggle(value)}
                      className="rounded border-gray-300 text-blue-600 
                               focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">
                      {label}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </Card>

        {/* Format d'export */}
        <Card>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">
            Format d'export
          </h3>

          <div className="space-y-4">
            {exportFormats.map(({ value, label, icon: Icon, description }) => (
              <label
                key={value}
                className={`
                  flex items-start p-4 rounded-lg border-2 cursor-pointer
                  transition-colors duration-200
                  ${formData.format === value
                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                    : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800'}
                `}
              >
                <input
                  type="radio"
                  name="format"
                  value={value}
                  checked={formData.format === value}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    format: e.target.value 
                  }))}
                  className="sr-only"
                />
                <Icon className={`
                  w-5 h-5 mt-0.5
                  ${formData.format === value
                    ? 'text-blue-500'
                    : 'text-gray-400'}
                `} />
                <div className="ml-3">
                  <p className={`
                    font-medium
                    ${formData.format === value
                      ? 'text-blue-900 dark:text-blue-100'
                      : 'text-gray-900 dark:text-white'}
                  `}>
                    {label}
                  </p>
                  <p className={`
                    text-sm
                    ${formData.format === value
                      ? 'text-blue-700 dark:text-blue-300'
                      : 'text-gray-500 dark:text-gray-400'}
                  `}>
                    {description}
                  </p>
                </div>
              </label>
            ))}
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              variant="primary"
              icon={Download}
              onClick={handleExport}
              loading={loading}
              disabled={loading}
            >
              Générer l'export
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

// Extension de apiService pour obtenir l'URL de base
apiService.getBaseUrl = () => {
  return 'http://localhost:8088'; // Utilisez votre config.API_URL si disponible
};

export default AuditExport;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AlertCircle, 
  AlertTriangle,
  Download, 
  Filter, 
  RefreshCw,
  Eye,
  Calendar,
  User,
  Tag,
  Globe,
  Server,
  Clock,
  X,
  ArrowLeft,
  Trash2,
  Activity,
  Search,
  Info
} from 'lucide-react';
import apiService from '../../services/api.service';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';

const AuditDashboard = () => {
  const navigate = useNavigate();
  
  // États pour la liste des audits
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [logs, setLogs] = useState([]);
  const [searchParams, setSearchParams] = useState({
    severity: null,
    userId: '',
    resourceType: '',
    resourceId: '',
    limit: 20,
    page: 0
  });
  const [totalItems, setTotalItems] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  
  // États pour les détails d'audit
  const [selectedAuditId, setSelectedAuditId] = useState(null);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [detailLoading, setDetailLoading] = useState(false);
  const [detailError, setDetailError] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Chargement initial et rafraîchissement
  useEffect(() => {
    if (!apiService.auth.isAuthenticated()) {
      navigate('/login');
      return;
    }
    fetchLogs();
  }, [navigate, searchParams]);

  // Charger les détails d'un audit lorsqu'il est sélectionné
  useEffect(() => {
    if (selectedAuditId) {
      fetchAuditDetails(selectedAuditId);
    } else {
      setSelectedAudit(null);
    }
  }, [selectedAuditId]);

  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'timestamp',
      header: 'Date',
      render: (log) => (
        <div className="flex items-center">
          <Calendar className="w-4 h-4 text-gray-400 mr-2" />
          <DateFormatter date={log.action.performedAt} format="full" />
        </div>
      )
    },
    {
      key: 'action',
      header: 'Action',
      render: (log) => (
        <div>
          <span className="font-medium">{log.action.actionName}</span>
          <p className="text-sm text-gray-500 mt-1">{log.action.description}</p>
        </div>
      )
    },
    {
      key: 'user',
      header: 'Utilisateur',
      render: (log) => (
        <div className="flex items-center">
          <User className="w-4 h-4 text-gray-400 mr-2" />
          <span>{log.action.performedBy}</span>
        </div>
      )
    },
    {
      key: 'resource',
      header: 'Ressource',
      render: (log) => (
        <div className="flex items-center">
          <Tag className="w-4 h-4 text-gray-400 mr-2" />
          <div>
            <span className="font-medium">{log.action.resourceType}</span>
            <p className="text-sm text-gray-500">{log.action.resourceId}</p>
          </div>
        </div>
      )
    },
    {
      key: 'severity',
      header: 'Sévérité',
      render: (log) => (
        <StatusBadge 
          status={log.severity.toLowerCase()} 
          customLabel={log.severity}
        />
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (log) => (
        <StatusBadge 
          status={log.success ? 'success' : 'error'}
          customLabel={log.success ? 'Succès' : 'Échec'}
        />
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (log) => (
        <div className="flex justify-end space-x-2">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            onClick={() => setSelectedAuditId(log.id)}
            title="Voir les détails"
          />
        </div>
      )
    }
  ];

  // Fonction pour charger les logs
  const fetchLogs = async () => {
    setLoading(true);
    setError('');

    try {
      let response;

      if (searchParams.severity) {
        response = await apiService.audit.getBySeverity(
          searchParams.severity, 
          searchParams.limit
        );
      } else if (searchParams.userId) {
        response = await apiService.audit.getByUser(
          searchParams.userId,
          searchParams.page,
          searchParams.limit
        );
      } else if (searchParams.resourceType && searchParams.resourceId) {
        response = await apiService.audit.getByResource(
          searchParams.resourceType,
          searchParams.resourceId,
          searchParams.page,
          searchParams.limit
        );
      } else {
        response = await apiService.audit.getRecent(searchParams.limit);
      }

      setLogs(response);

      // Si la réponse contient des métadonnées de pagination
      if (response.totalElements) {
        setTotalItems(response.totalElements);
      }
    } catch (err) {
      setError('Erreur lors du chargement des logs : ' + err.message);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les détails d'un audit
  const fetchAuditDetails = async (id) => {
    if (!id || id === 'undefined') {
      setDetailError('ID de log d\'audit invalide ou manquant');
      setDetailLoading(false);
      return;
    }

    setDetailLoading(true);
    setDetailError('');

    try {
      const data = await apiService.audit.getById(id);
      setSelectedAudit(data);
    } catch (err) {
      setDetailError('Erreur lors du chargement du log : ' + err.message);
    } finally {
      setDetailLoading(false);
    }
  };

  // Gérer la suppression d'un audit
  const handleDelete = async () => {
    if (!selectedAuditId) return;
    
    setDetailLoading(true);
    try {
      await apiService.audit.delete(selectedAuditId);
      setSelectedAuditId(null);
      setShowDeleteConfirm(false);
      fetchLogs(); // Rafraîchir la liste après suppression
    } catch (err) {
      setDetailError('Erreur lors de la suppression : ' + err.message);
      setDetailLoading(false);
    }
  };

  // Gestionnaire de changement de page
  const handlePageChange = (newPage) => {
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
  };

  // Gestionnaire pour les filtres
  const handleFilterChange = (filters) => {
    setSearchParams(prev => ({
      ...prev,
      ...filters,
      page: 0 // Réinitialiser la page lors du changement de filtres
    }));
  };

  // Fermer la vue détaillée
  const closeDetails = () => {
    setSelectedAuditId(null);
    setSelectedAudit(null);
    setDetailError('');
  };

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <Card>
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              Logs d'Audit
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Consultez et analysez l'historique des actions système
            </p>
          </div>

          <div className="flex items-center space-x-4">
            <Button
              variant="outline"
              icon={Filter}
              onClick={() => setShowFilters(!showFilters)}
            >
              Filtres
            </Button>
            <Button
              variant="outline"
              icon={Download}
              onClick={() => navigate('/[core] audit/[aud][03]-auditexport')}
            >
              Exporter
            </Button>
            <Button
              variant="outline"
              icon={RefreshCw}
              onClick={fetchLogs}
              loading={loading}
            >
              Actualiser
            </Button>
          </div>
        </div>

        {/* Message d'erreur */}
        {error && (
          <div className="mb-6">
            <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-900/10 dark:text-red-400">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          </div>
        )}

        {/* Filtres */}
        {showFilters && (
          <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Sévérité
                </label>
                <select
                  value={searchParams.severity || ''}
                  onChange={(e) => handleFilterChange({ severity: e.target.value || null })}
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                >
                  <option value="">Toutes</option>
                  <option value="INFO">Information</option>
                  <option value="WARNING">Avertissement</option>
                  <option value="ERROR">Erreur</option>
                  <option value="CRITICAL">Critique</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Utilisateur
                </label>
                <input
                  type="text"
                  value={searchParams.userId}
                  onChange={(e) => handleFilterChange({ userId: e.target.value })}
                  placeholder="ID utilisateur"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Type de ressource
                </label>
                <input
                  type="text"
                  value={searchParams.resourceType}
                  onChange={(e) => handleFilterChange({ resourceType: e.target.value })}
                  placeholder="Type de ressource"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  ID de ressource
                </label>
                <input
                  type="text"
                  value={searchParams.resourceId}
                  onChange={(e) => handleFilterChange({ resourceId: e.target.value })}
                  placeholder="ID de la ressource"
                  className="w-full p-2 border rounded-lg bg-white dark:bg-gray-700 
                           border-gray-300 dark:border-gray-600"
                />
              </div>
            </div>
          </div>
        )}
      </Card>

      {/* Affichage conditionnel: tableau ou détails */}
      {!selectedAuditId ? (
        <Card>
          <DataGrid
            data={logs}
            columns={columns}
            loading={loading}
            pagination={{
              page: searchParams.page,
              pageSize: searchParams.limit,
              totalItems: totalItems,
              onPageChange: handlePageChange
            }}
            emptyMessage="Aucun log d'audit trouvé"
          />
        </Card>
      ) : (
        <div className="space-y-6">
          {/* En-tête des détails */}
          <Card>
            <div className="flex justify-between items-start">
              <div className="flex items-center space-x-4">
                <Button
                  variant="outline"
                  icon={ArrowLeft}
                  onClick={closeDetails}
                >
                  Retour
                </Button>
                <div>
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                    Détails du log d'audit
                  </h2>
                  {selectedAudit && (
                    <p className="text-gray-500 dark:text-gray-400">
                      ID: {selectedAudit.id}
                    </p>
                  )}
                </div>
              </div>
              {selectedAudit && (
                <Button
                  variant="danger"
                  icon={Trash2}
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Supprimer
                </Button>
              )}
            </div>
          </Card>

          {/* Contenu des détails */}
          {detailLoading ? (
            <Card>
              <div className="animate-pulse space-y-4">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4" />
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
                  <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
                </div>
              </div>
            </Card>
          ) : detailError ? (
            <Card>
              <div className="text-center py-12">
                <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Erreur
                </h3>
                <p className="text-gray-500 dark:text-gray-400">{detailError}</p>
              </div>
            </Card>
          ) : !selectedAudit ? (
            <Card>
              <div className="text-center py-12">
                <Info className="w-12 h-12 text-blue-500 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Log introuvable
                </h3>
              </div>
            </Card>
          ) : (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Action et contexte */}
                <Card>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Action
                  </h3>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <Activity className="w-5 h-5 text-blue-500 mt-1" />
                      <div>
                        <p className="font-medium text-gray-900 dark:text-white">
                          {selectedAudit.action.actionName}
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {selectedAudit.action.description}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-3">
                      <Clock className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Effectué le
                        </p>
                        <DateFormatter 
                          date={selectedAudit.action.performedAt} 
                          format="full"
                          className="font-medium text-gray-900 dark:text-white"
                        />
                      </div>
                    </div>

                    <div className="flex items-center space-x-3">
                      <User className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Effectué par
                        </p>
                        <p className="font-medium text-gray-900 dark:text-white">
                          {selectedAudit.action.performedBy}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-3">
                      <Tag className="w-5 h-5 text-gray-400" />
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Ressource
                        </p>
                        <p className="font-medium text-gray-900 dark:text-white">
                          {selectedAudit.action.resourceType} / {selectedAudit.action.resourceId}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>

                {/* Statut et métadonnées */}
                <Card>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Statut
                  </h3>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-800 rounded-lg">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Sévérité
                        </p>
                        <StatusBadge 
                          status={selectedAudit.severity.toLowerCase()}
                          customLabel={selectedAudit.severity}
                          className="mt-1"
                        />
                      </div>
                      <div className="text-right">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Résultat
                        </p>
                        <StatusBadge 
                          status={selectedAudit.success ? 'success' : 'error'}
                          customLabel={selectedAudit.success ? 'Succès' : 'Échec'}
                          className="mt-1"
                        />
                      </div>
                    </div>

                    {selectedAudit.errorMessage && (
                      <div className="p-3 bg-red-50 dark:bg-red-900/10 rounded-lg">
                        <p className="text-sm font-medium text-red-800 dark:text-red-400">
                          Message d'erreur
                        </p>
                        <p className="mt-1 text-sm text-red-700 dark:text-red-300">
                          {selectedAudit.errorMessage}
                        </p>
                      </div>
                    )}

                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
                        Contexte d'exécution
                      </h4>
                      <div className="space-y-3">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <Globe className="w-4 h-4 text-gray-400" />
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              Application
                            </span>
                          </div>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">
                            {selectedAudit.context.applicationName}
                          </span>
                        </div>

                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <Server className="w-4 h-4 text-gray-400" />
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              Environnement
                            </span>
                          </div>
                          <span className="text-sm font-medium text-gray-900 dark:text-white">
                            {selectedAudit.context.environment}
                          </span>
                        </div>

                        {selectedAudit.duration && (
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <Clock className="w-4 h-4 text-gray-400" />
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                Durée
                              </span>
                            </div>
                            <span className="text-sm font-medium text-gray-900 dark:text-white">
                              {selectedAudit.duration}ms
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              {/* Changements détaillés si présents */}
              {selectedAudit.changes && selectedAudit.changes.length > 0 && (
                <Card>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                    Changements
                  </h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Champ
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Ancienne valeur
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nouvelle valeur
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                        {selectedAudit.changes.map((change, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                              {change.fieldName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              {change.oldValue || '-'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              {change.newValue || '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              )}
            </>
          )}
        </div>
      )}

      {/* Modal de confirmation de suppression */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Card className="w-full max-w-md mx-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Confirmer la suppression
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6">
              Êtes-vous sûr de vouloir supprimer ce log d'audit ? Cette action est irréversible.
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                variant="outline"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Annuler
              </Button>
              <Button
                variant="danger"
                icon={Trash2}
                onClick={handleDelete}
                loading={detailLoading}
              >
                Supprimer
              </Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AuditDashboard;
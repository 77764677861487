import React, { useState, useEffect, useMemo } from 'react';
import {
  Database,
  Edit,
  Search,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  Layers,
  Eye,
  EyeOff,
  Filter,
  ChevronDown,
  Sliders,
  ArrowRight,
  Check,
  Server,
  FileText,
  ShieldAlert,
  Copy,
  Download,
  AlertCircle,
  Info,
  Settings,
  Upload,
  Star
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import apiService from '../../services/api.service';

const ConfigurationValuesManagement = () => {
  // États de données
  const [configurations, setConfigurations] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [configGroups, setConfigGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les valeurs de configuration chargées
  const [configValues, setConfigValues] = useState({});
  
  // États pour les filtres
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [selectedScope, setSelectedScope] = useState('all');
  const [showOnlyMandatory, setShowOnlyMandatory] = useState(false);
  const [hideEmptyValues, setHideEmptyValues] = useState(false);
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(false);
  const [sortField, setSortField] = useState('key');
  const [sortDirection, setSortDirection] = useState('asc');
  const [showFilters, setShowFilters] = useState(false);
  
  // États pour les environnements
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [environmentCategories, setEnvironmentCategories] = useState({
    DEVELOPMENT: [],
    TESTING: [],
    STAGING: [],
    PRODUCTION: [],
    DR: []
  });
  
  // État pour l'édition
  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState('');
  
  // État pour la sélection des configurations
  const [selectedConfigs, setSelectedConfigs] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [bulkTargetEnvironment, setBulkTargetEnvironment] = useState('');
  const [bulkLoadingState, setBulkLoadingState] = useState({
    loading: false,
    progress: 0,
    total: 0
  });
  
  // Chargement initial des données
  useEffect(() => {
    fetchInitialData();
  }, []);
  
  // Chargement des valeurs lorsque les environnements sélectionnés changent
  useEffect(() => {
    if (environments.length > 0 && selectedEnvironments.length > 0) {
      fetchConfigurationValues();
    }
  }, [selectedEnvironments]);
  
  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [configurationsData, environmentsData, groupsData] = await Promise.all([
        apiService.config.configurations.getAll(),
        apiService.config.environments.getAll(),
        apiService.config.groups.getAll()
      ]);
      
      setConfigurations(configurationsData || []);
      setEnvironments(environmentsData || []);
      setConfigGroups(groupsData || []);
      
      // Organiser les environnements par catégories
      const envCategories = {
        DEVELOPMENT: [],
        TESTING: [],
        STAGING: [],
        PRODUCTION: [],
        DR: []
      };
      
      environmentsData.forEach(env => {
        if (envCategories[env.type]) {
          envCategories[env.type].push(env);
        } else {
          envCategories.DEVELOPMENT.push(env);
        }
      });
      
      // Trier chaque catégorie par priorité puis par nom
      Object.keys(envCategories).forEach(type => {
        envCategories[type].sort((a, b) => {
          if (a.priority !== b.priority) {
            return (b.priority || 0) - (a.priority || 0);
          }
          return a.name.localeCompare(b.name);
        });
      });
      
      setEnvironmentCategories(envCategories);
      
      // Par défaut, sélectionner le premier environnement de développement
      if (environmentsData && environmentsData.length > 0) {
        const devEnv = environmentsData.find(env => env.type === 'DEVELOPMENT') || environmentsData[0];
        setSelectedEnvironments([devEnv.code]);
        setBulkTargetEnvironment(devEnv.code);
      }
      
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des données:', err);
      setError('Impossible de charger les données. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchConfigurationValues = async () => {
    setLoading(true);
    try {
      const newConfigValues = { ...configValues };
      
      // Pour chaque environnement sélectionné
      for (const envCode of selectedEnvironments) {
        if (!newConfigValues[envCode]) {
          newConfigValues[envCode] = {};
        }
        
        // Récupérer toutes les clés de configuration
        const configKeys = configurations.map(config => config.key);
        
        // Récupérer les valeurs pour cet environnement en une seule requête
        try {
          const bulkValues = await apiService.config.configurations.getValues(configKeys, envCode);
          if (bulkValues) {
            newConfigValues[envCode] = { ...bulkValues };
          }
        } catch (error) {
          console.error(`Erreur lors de la récupération des valeurs pour l'environnement ${envCode}:`, error);
          // Continuer avec les autres environnements
        }
      }
      
      setConfigValues(newConfigValues);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des valeurs:', err);
      setError('Impossible de charger toutes les valeurs de configuration.');
    } finally {
      setLoading(false);
    }
  };
  
  // Déterminer les différences entre les valeurs et valeurs par défaut
  const getDiffStatus = (config, envCode) => {
    const configValue = configValues[envCode]?.[config.key];
    
    if (configValue === undefined || configValue === null) {
      return config.defaultValue ? 'default' : 'missing';
    }
    
    if (config.defaultValue !== undefined && config.defaultValue !== null) {
      return configValue === config.defaultValue ? 'identical' : 'different';
    }
    
    return 'custom';
  };
  
  // Vérifier les différences entre environnements
  const hasDifferences = (config) => {
    if (selectedEnvironments.length <= 1) return false;
    
    const values = selectedEnvironments.map(envCode => configValues[envCode]?.[config.key]);
    const firstValue = values[0];
    
    return values.some(value => value !== firstValue);
  };
  
  // Filtrer et trier les configurations
  const filteredAndSortedConfigurations = useMemo(() => {
    let results = [...configurations];
    
    // Filtrage textuel
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      results = results.filter(config => 
        (config.key && config.key.toLowerCase().includes(searchLower)) ||
        (config.description && config.description.toLowerCase().includes(searchLower))
      );
    }
    
    // Filtrage par groupe
    if (selectedGroup !== 'all') {
      results = results.filter(config => 
        config.group && (config.group.id?.toString() === selectedGroup || 
                         config.group.name === selectedGroup)
      );
    }
    
    // Filtrage par scope
    if (selectedScope !== 'all') {
      results = results.filter(config => config.scope === selectedScope);
    }
    
    // Configurations obligatoires uniquement
    if (showOnlyMandatory) {
      results = results.filter(config => config.isMandatory);
    }
    
    // Masquer les configurations sans valeur
    if (hideEmptyValues) {
      results = results.filter(config => {
        for (const envCode of selectedEnvironments) {
          if (configValues[envCode]?.[config.key]) {
            return true;
          }
        }
        return false;
      });
    }
    
    // Afficher uniquement les configurations avec des différences entre environnements
    if (showOnlyDifferences && selectedEnvironments.length > 1) {
      results = results.filter(config => hasDifferences(config));
    }
    
    // Tri
    results.sort((a, b) => {
      let valA, valB;
      
      // Déterminer les valeurs à comparer selon le champ de tri
      switch (sortField) {
        case 'key':
          valA = a.key?.toLowerCase() || '';
          valB = b.key?.toLowerCase() || '';
          break;
        case 'group':
          valA = a.group?.name?.toLowerCase() || '';
          valB = b.group?.name?.toLowerCase() || '';
          break;
        case 'scope':
          valA = a.scope || '';
          valB = b.scope || '';
          break;
        default:
          valA = a[sortField] || '';
          valB = b[sortField] || '';
      }
      
      // Appliquer la direction du tri
      if (sortDirection === 'asc') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    });
    
    return results;
  }, [
    configurations,
    searchTerm,
    selectedGroup,
    selectedScope,
    showOnlyMandatory,
    hideEmptyValues,
    showOnlyDifferences,
    selectedEnvironments,
    configValues,
    sortField,
    sortDirection
  ]);
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };
  
  const handleScopeChange = (e) => {
    setSelectedScope(e.target.value);
  };
  
  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedGroup('all');
    setSelectedScope('all');
    setShowOnlyMandatory(false);
    setHideEmptyValues(false);
    setShowOnlyDifferences(false);
  };
  
  const handleEnvironmentChange = (envCode) => {
    setSelectedEnvironments(prev => {
      if (prev.includes(envCode)) {
        return prev.filter(env => env !== envCode);
      } else {
        return [...prev, envCode];
      }
    });
  };
  
  const handleSelectAllEnvsByType = (type, select) => {
    const typeEnvs = environmentCategories[type] || [];
    const envCodes = typeEnvs.map(env => env.code);
    
    if (select) {
      // Ajouter uniquement les environnements qui ne sont pas déjà sélectionnés
      setSelectedEnvironments(prev => {
        const newSelection = [...prev];
        envCodes.forEach(code => {
          if (!newSelection.includes(code)) {
            newSelection.push(code);
          }
        });
        return newSelection;
      });
    } else {
      // Retirer les environnements du type spécifié
      setSelectedEnvironments(prev => 
        prev.filter(code => !envCodes.includes(code))
      );
    }
  };
  
  const handleStartEdit = (configKey, envCode, currentValue) => {
    setEditingCell({ configKey, envCode });
    setEditValue(currentValue || '');
  };
  
  const handleSaveEdit = async (save) => {
    if (!editingCell || !save) {
      setEditingCell(null);
      return;
    }
    
    const { configKey, envCode } = editingCell;
    
    // Si la valeur n'a pas changé, ne rien faire
    if (configValues[envCode]?.[configKey] === editValue) {
      setEditingCell(null);
      return;
    }
    
    setLoading(true);
    
    try {
      await apiService.config.configurations.setValue(configKey, editValue, envCode);
      
      // Mettre à jour l'état local
      setConfigValues(prev => ({
        ...prev,
        [envCode]: {
          ...prev[envCode],
          [configKey]: editValue
        }
      }));
      
      setSuccessMessage(`Valeur mise à jour avec succès pour ${configKey} dans ${envCode}`);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la mise à jour de la valeur:', err);
      setError(`Impossible de mettre à jour la valeur pour ${configKey}: ${err.message}`);
    } finally {
      setLoading(false);
      setEditingCell(null);
    }
  };
  
  const toggleSelectConfig = (configKey) => {
    setSelectedConfigs(prev => {
      if (prev.includes(configKey)) {
        return prev.filter(key => key !== configKey);
      } else {
        return [...prev, configKey];
      }
    });
  };
  
  const handleSelectAllConfigs = (select) => {
    if (select) {
      setSelectedConfigs(filteredAndSortedConfigurations.map(config => config.key));
    } else {
      setSelectedConfigs([]);
    }
  };
  
  const refreshData = async () => {
    await fetchInitialData();
    if (selectedEnvironments.length > 0) {
      await fetchConfigurationValues();
    }
  };
  
  // Ouvrir le modal pour appliquer les valeurs par défaut
  const handleOpenBulkModal = () => {
    if (selectedConfigs.length === 0) {
      setError("Veuillez sélectionner au moins une configuration");
      return;
    }
    
    if (selectedEnvironments.length === 0) {
      setError("Veuillez sélectionner au moins un environnement cible");
      return;
    }
    
    setShowBulkModal(true);
  };
  
  // Appliquer les valeurs par défaut aux configurations sélectionnées
  const handleApplyDefaultValues = async () => {
    if (!bulkTargetEnvironment || selectedConfigs.length === 0) {
      setError("Veuillez sélectionner un environnement cible et au moins une configuration");
      return;
    }
    
    setBulkLoadingState({
      loading: true,
      progress: 0,
      total: selectedConfigs.length
    });
    
    try {
      let successCount = 0;
      let failCount = 0;
      
      // Traiter chaque configuration sélectionnée
      for (let i = 0; i < selectedConfigs.length; i++) {
        const configKey = selectedConfigs[i];
        const config = configurations.find(c => c.key === configKey);
        
        // Vérifier si la configuration a une valeur par défaut
        if (config && config.defaultValue !== undefined && config.defaultValue !== null) {
          try {
            await apiService.config.configurations.setValue(
              configKey, 
              config.defaultValue, 
              bulkTargetEnvironment
            );
            
            // Mettre à jour l'état local
            setConfigValues(prev => ({
              ...prev,
              [bulkTargetEnvironment]: {
                ...prev[bulkTargetEnvironment],
                [configKey]: config.defaultValue
              }
            }));
            
            successCount++;
          } catch (error) {
            console.error(`Erreur lors de l'application de la valeur par défaut pour ${configKey}:`, error);
            failCount++;
          }
        } else {
          failCount++;
        }
        
        // Mettre à jour la progression
        setBulkLoadingState(prev => ({
          ...prev,
          progress: i + 1
        }));
      }
      
      // Afficher le message de succès/erreur
      if (failCount === 0) {
        setSuccessMessage(`${successCount} valeurs par défaut appliquées avec succès dans ${bulkTargetEnvironment}`);
      } else if (successCount === 0) {
        setError(`Impossible d'appliquer les valeurs par défaut. Assurez-vous que les configurations ont des valeurs par défaut définies.`);
      } else {
        setSuccessMessage(`${successCount} valeurs appliquées avec succès, ${failCount} échecs dans ${bulkTargetEnvironment}`);
      }
      
      // Fermer le modal
      setShowBulkModal(false);
      
      // Réinitialiser les configurations sélectionnées
      setSelectedConfigs([]);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (err) {
      console.error('Erreur lors de l\'application des valeurs par défaut:', err);
      setError('Une erreur est survenue lors de l\'application des valeurs par défaut');
    } finally {
      setBulkLoadingState({
        loading: false,
        progress: 0,
        total: 0
      });
    }
  };
  
  // Générer les colonnes pour la grille de données
  const generateColumns = () => {
    // Colonne pour la sélection des configurations
    const columns = [
      {
        key: 'select',
        header: () => (
          <div className="flex justify-center">
            <input
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              checked={
                filteredAndSortedConfigurations.length > 0 && 
                selectedConfigs.length === filteredAndSortedConfigurations.length
              }
              onChange={(e) => handleSelectAllConfigs(e.target.checked)}
            />
          </div>
        ),
        width: 50,
        render: (row) => (
          <div className="flex justify-center">
            <input
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              checked={selectedConfigs.includes(row.key)}
              onChange={() => toggleSelectConfig(row.key)}
            />
          </div>
        )
      },
      // Colonne pour la configuration
      {
        key: 'key',
        header: 'Configuration',
        minWidth: 220,
        render: (row) => (
          <div className="flex flex-col space-y-1">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-8 w-8 bg-gradient-to-br from-blue-100 to-blue-200 dark:from-blue-900/30 dark:to-blue-800/30 rounded-lg flex items-center justify-center text-blue-600 dark:text-blue-300 shadow-sm">
                <FileText size={16} />
              </div>
              <div className="ml-3">
                <div className="font-medium text-gray-900 dark:text-white flex items-center">
                  {row.key}
                  {row.isMandatory && (
                    <Star size={14} className="ml-1 text-amber-500" />
                  )}
                  {hasDifferences(row) && (
                    <AlertCircle size={14} className="ml-1 text-orange-500" title="Cette configuration a des valeurs différentes entre les environnements" />
                  )}
                </div>
                <div className="text-xs text-gray-500 dark:text-gray-400">
                  {row.group?.name || 'Sans groupe'} • {row.scope}
                </div>
              </div>
            </div>
            {row.description && (
              <div className="text-xs text-gray-500 dark:text-gray-400 pl-11 pr-3 truncate">
                {row.description}
              </div>
            )}
          </div>
        )
      }
    ];
    
    // Colonne pour la valeur par défaut
    columns.push({
      key: 'defaultValue',
      header: 'Valeur par défaut',
      minWidth: 180,
      render: (row) => (
        <div className="text-sm">
          {row.defaultValue ? (
            <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded font-mono text-xs block overflow-hidden text-ellipsis">
              {row.defaultValue}
            </code>
          ) : (
            <span className="text-gray-400 dark:text-gray-500 italic">
              Non définie
            </span>
          )}
        </div>
      )
    });
    
    // Ajouter une colonne pour chaque environnement sélectionné
    selectedEnvironments.forEach(envCode => {
      const env = environments.find(e => e.code === envCode);
      
      columns.push({
        key: `env_${envCode}`,
        header: () => (
          <div className="flex flex-col items-center">
            <div className={`
              px-2 py-1 rounded-md text-xs font-medium
              ${env && env.type === 'PRODUCTION' 
                ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' 
                : env && env.type === 'STAGING'
                  ? 'bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300'
                  : env && env.type === 'TESTING'
                    ? 'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300'
                    : 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
              }`}
            >
              {env?.name || envCode}
            </div>
            {env && !env.isActive && (
              <span className="text-xs text-red-500 mt-1">(inactif)</span>
            )}
          </div>
        ),
        minWidth: 180,
        render: (row) => {
          const isEditing = editingCell && 
                          editingCell.configKey === row.key && 
                          editingCell.envCode === envCode;
          
          const value = configValues[envCode]?.[row.key];
          const diffStatus = getDiffStatus(row, envCode);
          
          return (
            <div className="relative">
              {isEditing ? (
                <div className="flex">
                  <input
                    type="text"
                    className="block w-full border border-blue-300 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    autoFocus
                    onBlur={() => handleSaveEdit(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSaveEdit(true);
                      if (e.key === 'Escape') setEditingCell(null);
                    }}
                  />
                </div>
              ) : (
                <div 
                  className={`
                    flex justify-between items-center group cursor-pointer p-1 
                    rounded transition-colors duration-200
                    ${diffStatus === 'identical' 
                      ? 'hover:bg-green-50 dark:hover:bg-green-900/20' 
                      : diffStatus === 'different'
                        ? 'hover:bg-amber-50 dark:hover:bg-amber-900/20'
                        : diffStatus === 'default'
                          ? 'hover:bg-blue-50 dark:hover:bg-blue-900/20' 
                          : 'hover:bg-gray-100 dark:hover:bg-gray-700/50'}
                  `}
                  onClick={() => handleStartEdit(row.key, envCode, value)}
                >
                  <div className="font-mono text-sm flex-1 overflow-hidden">
                    {value ? (
                      <div className={`
                        px-2 py-1 rounded text-xs
                        ${diffStatus === 'identical' 
                          ? 'bg-green-100 text-green-800 dark:bg-green-900/40 dark:text-green-300' 
                          : diffStatus === 'different'
                            ? 'bg-amber-100 text-amber-800 dark:bg-amber-900/40 dark:text-amber-300'
                            : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300'}
                      `}>
                        {value}
                      </div>
                    ) : diffStatus === 'default' ? (
                      <div className="bg-blue-100 text-blue-800 dark:bg-blue-900/40 dark:text-blue-300 px-2 py-1 rounded text-xs italic">
                        {row.defaultValue} (défaut)
                      </div>
                    ) : (
                      <div className="text-gray-400 dark:text-gray-500 italic px-2 py-1 text-xs">
                        Non définie
                      </div>
                    )}
                  </div>
                  <Button
                    variant="text"
                    size="xs"
                    icon={Edit}
                    className="opacity-0 group-hover:opacity-100 ml-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStartEdit(row.key, envCode, value);
                    }}
                  />
                </div>
              )}
            </div>
          );
        }
      });
    });
    
    return columns;
  };
  
  // Rendu des environnements groupés par type
  const renderEnvironmentGroups = () => {
    const envTypes = Object.keys(environmentCategories);
    
    return (
      <div className="space-y-4">
        {envTypes.map(type => {
          const envs = environmentCategories[type];
          if (envs.length === 0) return null;
          
          const allSelected = envs.every(env => selectedEnvironments.includes(env.code));
          const someSelected = envs.some(env => selectedEnvironments.includes(env.code));
          
          return (
            <div key={type} className="space-y-2">
              <div className="flex items-center justify-between">
                <h4 className={`
                  text-sm font-medium px-3 py-1 rounded-md inline-flex items-center
                  ${type === 'PRODUCTION' 
                    ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' 
                    : type === 'STAGING'
                      ? 'bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300'
                      : type === 'TESTING'
                        ? 'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300'
                        : type === 'DR'
                          ? 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-300'
                          : 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
                  }`}
                >
                  {type === 'DEVELOPMENT' && 'Développement'}
                  {type === 'TESTING' && 'Test'}
                  {type === 'STAGING' && 'Pré-production'}
                  {type === 'PRODUCTION' && 'Production'}
                  {type === 'DR' && 'Reprise après sinistre'}
                </h4>
                
                <div className="flex items-center">
                  <button
                    className={`
                      text-xs px-2 py-1 rounded ${
                        allSelected 
                          ? 'text-red-600 hover:text-red-800 dark:text-red-400 underline' 
                          : 'text-blue-600 hover:text-blue-800 dark:text-blue-400 underline'
                      }
                    `}
                    onClick={() => handleSelectAllEnvsByType(type, !allSelected)}
                  >
                    {allSelected ? 'Tout désélectionner' : 'Tout sélectionner'}
                  </button>
                </div>
              </div>
              
              <div className="flex flex-wrap gap-2">
                {envs.map(env => (
                  <div 
                    key={env.code}
                    className={`
                      inline-flex items-center px-3 py-2 rounded-md cursor-pointer transition-colors duration-200
                      ${selectedEnvironments.includes(env.code) 
                        ? 'bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/30 dark:to-blue-800/30 text-blue-800 dark:text-blue-300 border border-blue-300 dark:border-blue-700 shadow-sm' 
                        : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-700'}
                      ${!env.isActive && 'opacity-60'}
                    `}
                    onClick={() => handleEnvironmentChange(env.code)}
                  >
                    <span className={`
                      inline-flex w-3 h-3 rounded-full mr-2
                      ${env.isActive 
                        ? 'bg-green-500' 
                        : 'bg-gray-400'}
                    `}></span>
                    {env.name}
                    {selectedEnvironments.includes(env.code) && (
                      <Check size={16} className="ml-2 text-blue-600 dark:text-blue-400" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  
  // Rendu des filtres avancés
  const renderAdvancedFilters = () => (
    <div className="p-4 space-y-4 border-b border-gray-200 dark:border-gray-700">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
          <Sliders className="h-5 w-5 mr-2 text-gray-500" />
          Filtres avancés
        </h3>
        <Button
          variant="text"
          size="sm"
          onClick={handleResetFilters}
        >
          Réinitialiser les filtres
        </Button>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Filtre par groupe */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Groupe
          </label>
          <select
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            value={selectedGroup}
            onChange={handleGroupChange}
          >
            <option value="all">Tous les groupes</option>
            {configGroups.map(group => (
              <option key={group.id} value={group.id}>{group.name}</option>
            ))}
          </select>
        </div>
        
        {/* Filtre par scope */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Portée
          </label>
          <select
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            value={selectedScope}
            onChange={handleScopeChange}
          >
            <option value="all">Toutes les portées</option>
            <option value="GLOBAL">Global</option>
            <option value="ENVIRONMENT">Environnement</option>
            <option value="TENANT">Tenant</option>
            <option value="APPLICATION">Application</option>
          </select>
        </div>
      </div>
      
      {/* Options supplémentaires */}
      <div className="flex flex-wrap gap-4">
        <div className="flex items-center">
          <input
            id="show-mandatory"
            type="checkbox"
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            checked={showOnlyMandatory}
            onChange={(e) => setShowOnlyMandatory(e.target.checked)}
          />
          <label htmlFor="show-mandatory" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
            Obligatoires uniquement
          </label>
        </div>
        
        <div className="flex items-center">
          <input
            id="hide-empty"
            type="checkbox"
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            checked={hideEmptyValues}
            onChange={(e) => setHideEmptyValues(e.target.checked)}
          />
          <label htmlFor="hide-empty" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
            Masquer configurations sans valeur
          </label>
        </div>
        
        {selectedEnvironments.length > 1 && (
          <div className="flex items-center">
            <input
              id="show-differences"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              checked={showOnlyDifferences}
              onChange={(e) => setShowOnlyDifferences(e.target.checked)}
            />
            <label htmlFor="show-differences" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Afficher uniquement les différences
            </label>
          </div>
        )}
      </div>
      
      {/* Statistiques de filtrage */}
      {filteredAndSortedConfigurations.length !== configurations.length && (
        <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
          <span className="font-medium">{filteredAndSortedConfigurations.length}</span> configuration(s) sur <span className="font-medium">{configurations.length}</span> correspondent aux filtres.
        </div>
      )}
    </div>
  );
  
  // Légende des statuts de valeur
  const renderValueStatusLegend = () => (
    <div className="flex flex-wrap gap-3 text-xs">
      <div className="flex items-center">
        <div className="w-4 h-4 bg-green-100 dark:bg-green-900/40 rounded mr-1"></div>
        <span className="text-gray-700 dark:text-gray-300">Identique</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 h-4 bg-amber-100 dark:bg-amber-900/40 rounded mr-1"></div>
        <span className="text-gray-700 dark:text-gray-300">Différente</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 h-4 bg-blue-100 dark:bg-blue-900/40 rounded mr-1"></div>
        <span className="text-gray-700 dark:text-gray-300">Valeur par défaut</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 h-4 bg-gray-100 dark:bg-gray-800 rounded mr-1"></div>
        <span className="text-gray-700 dark:text-gray-300">Personnalisée</span>
      </div>
    </div>
  );
  
  // Rendu principal
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Layers className="h-6 w-6 mr-2 text-purple-500" />
            Valeurs de configuration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les valeurs des configurations par environnement
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline"
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
            className={showFilters ? 'border-blue-500 text-blue-600 dark:text-blue-400' : ''}
          >
            Filtres
            {showFilters ? <ChevronDown className="ml-1 h-4 w-4" /> : <ChevronDown className="ml-1 h-4 w-4" />}
          </Button>
          <Button
            variant="outline"
            icon={Upload}
            onClick={handleOpenBulkModal}
            disabled={selectedConfigs.length === 0}
          >
            Appliquer valeurs par défaut ({selectedConfigs.length})
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-gradient-to-r from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-800/20 border-l-4 border-green-500 p-4 rounded-md shadow-sm animate-fadeIn">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-gradient-to-r from-red-50 to-red-100 dark:from-red-900/20 dark:to-red-800/20 border-l-4 border-red-500 p-4 rounded-md shadow-sm animate-fadeIn">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Section Environnements */}
      <Card className="overflow-visible">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4 flex items-center">
            <Server className="h-5 w-5 mr-2 text-gray-500" />
            Sélection des environnements
          </h3>
          
          {environments.length === 0 ? (
            <div className="text-center py-4">
              <ShieldAlert className="h-12 w-12 text-gray-400 mx-auto mb-2" />
              <p className="text-gray-500 dark:text-gray-400">
                Aucun environnement disponible. Veuillez en créer un d'abord.
              </p>
            </div>
          ) : (
            renderEnvironmentGroups()
          )}
        </div>
        
        {selectedEnvironments.length > 0 && (
          <div className="p-4 bg-gray-50 dark:bg-gray-800/50 flex flex-wrap items-center justify-between">
            <div className="text-sm text-gray-600 dark:text-gray-300">
              <span className="font-medium">{selectedEnvironments.length}</span> environnement(s) sélectionné(s)
            </div>
            
            {renderValueStatusLegend()}
          </div>
        )}
      </Card>
      
      {/* Filtres et recherche */}
      <Card>
        {/* Barre de recherche toujours visible */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="relative">
            <input
              type="text"
              className="w-full p-3 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Rechercher par clé, description..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            {searchTerm && (
              <button
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              </button>
            )}
          </div>
        </div>
        
        {/* Filtres avancés développables */}
        <div 
          className={`${
            showFilters ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          } transition-all duration-300 ease-in-out`}
        >
          {renderAdvancedFilters()}
        </div>
        
        {/* Résumé des filtres actifs */}
        {(selectedGroup !== 'all' || selectedScope !== 'all' || showOnlyMandatory || hideEmptyValues || showOnlyDifferences || searchTerm) && (
          <div className="p-3 bg-gray-50 dark:bg-gray-800/50 flex flex-wrap gap-2 items-center">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Filtres actifs:
            </span>
            
            {searchTerm && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300">
                Recherche: {searchTerm}
                <button 
                  className="ml-1 text-blue-600 hover:text-blue-800 dark:text-blue-400"
                  onClick={() => setSearchTerm('')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {selectedGroup !== 'all' && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300">
                Groupe: {configGroups.find(g => g.id.toString() === selectedGroup)?.name || selectedGroup}
                <button 
                  className="ml-1 text-purple-600 hover:text-purple-800 dark:text-purple-400"
                  onClick={() => setSelectedGroup('all')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {selectedScope !== 'all' && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900/30 dark:text-indigo-300">
                Portée: {selectedScope}
                <button 
                  className="ml-1 text-indigo-600 hover:text-indigo-800 dark:text-indigo-400"
                  onClick={() => setSelectedScope('all')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {showOnlyMandatory && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300">
                Obligatoires uniquement
                <button 
                  className="ml-1 text-amber-600 hover:text-amber-800 dark:text-amber-400"
                  onClick={() => setShowOnlyMandatory(false)}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {hideEmptyValues && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-900/30 dark:text-teal-300">
                Sans valeurs masquées
                <button 
                  className="ml-1 text-teal-600 hover:text-teal-800 dark:text-teal-400"
                  onClick={() => setHideEmptyValues(false)}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {showOnlyDifferences && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-300">
                Différences uniquement
                <button 
                  className="ml-1 text-orange-600 hover:text-orange-800 dark:text-orange-400"
                  onClick={() => setShowOnlyDifferences(false)}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            <button 
              className="ml-auto text-xs text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 underline"
              onClick={handleResetFilters}
            >
              Effacer tous les filtres
            </button>
          </div>
        )}
      </Card>
      
      {/* Matrice des configurations */}
      <Card>
        {selectedEnvironments.length > 0 ? (
          <div className="overflow-x-auto">
            <DataGrid
              data={filteredAndSortedConfigurations}
              columns={generateColumns()}
              loading={loading}
              emptyMessage={
                <div className="flex flex-col items-center justify-center py-12">
                  <Settings className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    Aucune configuration trouvée
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 text-center max-w-md mb-4">
                    {searchTerm || selectedGroup !== 'all' || selectedScope !== 'all' || showOnlyMandatory || hideEmptyValues || showOnlyDifferences
                      ? "Aucune configuration ne correspond aux critères de recherche actuels."
                      : "Aucune configuration disponible. Créez d'abord des configurations dans la section Gestion des configurations."}
                  </p>
                </div>
              }
              stickyHeader
              horizontalScroll
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <Filter className="h-12 w-12 text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              Sélectionnez au moins un environnement
            </h3>
            <p className="text-gray-500 dark:text-gray-400 max-w-md">
              Veuillez sélectionner un ou plusieurs environnements pour afficher et gérer les valeurs de configuration.
            </p>
          </div>
        )}
      </Card>
      
      {/* Modal d'application des valeurs par défaut */}
      {showBulkModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4 animate-fadeIn">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md overflow-hidden">
            <div className="flex justify-between items-center bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/30 dark:to-blue-800/30 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                <Upload className="h-5 w-5 mr-2 text-blue-600 dark:text-blue-400" />
                Appliquer les valeurs par défaut
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowBulkModal(false)}
                disabled={bulkLoadingState.loading}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="p-6 space-y-4">
              <div className="bg-amber-50 dark:bg-amber-900/20 p-4 rounded-md border-l-4 border-amber-500">
                <div className="flex">
                  <Info className="h-5 w-5 text-amber-500 mr-2 flex-shrink-0" />
                  <div className="text-sm text-amber-700 dark:text-amber-300">
                    Cette action va appliquer les valeurs par défaut aux {selectedConfigs.length} configuration(s) sélectionnée(s) dans l'environnement cible. Les valeurs existantes seront écrasées.
                  </div>
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Environnement cible *
                </label>
                <select
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={bulkTargetEnvironment}
                  onChange={(e) => setBulkTargetEnvironment(e.target.value)}
                  disabled={bulkLoadingState.loading}
                  required
                >
                  <option value="">Sélectionner un environnement</option>
                  {environments.map(env => (
                    <option key={env.code} value={env.code}>{env.name} ({env.code})</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Configurations sélectionnées ({selectedConfigs.length})
                </label>
                <div className="max-h-40 overflow-y-auto p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700 text-sm">
                  {selectedConfigs.map(configKey => {
                    const config = configurations.find(c => c.key === configKey);
                    return (
                      <div key={configKey} className="flex items-center py-1">
                        <div className="w-4 h-4 mr-2">
                          {config && config.defaultValue !== undefined && config.defaultValue !== null ? (
                            <CheckCircle className="h-4 w-4 text-green-500" />
                          ) : (
                            <X className="h-4 w-4 text-red-500" />
                          )}
                        </div>
                        <div className="flex-1 truncate">{configKey}</div>
                        <div className="text-xs text-gray-500 dark:text-gray-400 truncate max-w-[150px]">
                          {config?.defaultValue || <span className="italic text-red-500">Sans valeur par défaut</span>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              
              {bulkLoadingState.loading && (
                <div className="space-y-2">
                  <div className="text-sm text-gray-700 dark:text-gray-300 flex justify-between">
                    <span>Progression:</span>
                    <span>{bulkLoadingState.progress} / {bulkLoadingState.total}</span>
                  </div>
                  <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                    <div 
                      className="bg-blue-600 h-2.5 rounded-full" 
                      style={{ width: `${(bulkLoadingState.progress / bulkLoadingState.total) * 100}%` }}
                    ></div>
                  </div>
                </div>
              )}
              
              <div className="flex justify-end space-x-3 pt-4 border-t border-gray-200 dark:border-gray-700">
                <Button
                  variant="outline"
                  onClick={() => setShowBulkModal(false)}
                  disabled={bulkLoadingState.loading}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  icon={Upload}
                  onClick={handleApplyDefaultValues}
                  loading={bulkLoadingState.loading}
                  disabled={!bulkTargetEnvironment || selectedConfigs.length === 0}
                >
                  Appliquer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigurationValuesManagement;
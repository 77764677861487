import React, { useState, useEffect } from 'react';
import {
  FolderTree,
  PlusCircle,
  Edit,
  Trash2,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  ChevronDown,
  ChevronRight,
  Folder,
  FolderPlus,
  FolderX,
  File,
  Search
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const ConfigurationGroupsManagement = () => {
  // États de données
  const [groups, setGroups] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // États pour les formulaires
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [groupForm, setGroupForm] = useState({
    name: '',
    description: '',
    path: '',
    isActive: true
  });
  
  // Chargement initial des données
  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const [groupsData, configurationsData] = await Promise.all([
        apiService.config.groups.getAll(),
        apiService.config.configurations.getAll()
      ]);
      
      setGroups(groupsData || []);
      setConfigurations(configurationsData || []);
      
      // Par défaut, tous les groupes sont développés
      const expanded = {};
      groupsData.forEach(group => {
        expanded[group.id] = true;
      });
      setExpandedGroups(expanded);
      
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des données:', err);
      setError('Impossible de charger les données. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrage des groupes basé sur la recherche
  const filteredGroups = groups.filter(group => {
    const searchableValues = [
      group.name,
      group.description,
      group.path
    ].map(val => (val || '').toLowerCase());
    
    return searchTerm === '' || searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
  });
  
  // Tri des groupes par chemin pour l'affichage hiérarchique
  const sortedGroups = [...filteredGroups].sort((a, b) => {
    return (a.path || '').localeCompare(b.path || '');
  });
  
  // Calcul de la structure arborescente
  const buildGroupTree = () => {
    const groupMap = {};
    const roots = [];
    
    // Initialiser la carte des groupes
    sortedGroups.forEach(group => {
      groupMap[group.id] = {
        ...group,
        children: [],
        level: (group.path || '').split('/').filter(Boolean).length
      };
    });
    
    // Construire la structure arborescente
    Object.values(groupMap).forEach(group => {
      const parentPath = (group.path || '').split('/').filter(Boolean).slice(0, -1).join('/');
      
      const parentGroup = Object.values(groupMap).find(g => 
        (g.path || '') === parentPath || 
        (g.path || '') === `/${parentPath}`
      );
      
      if (parentGroup) {
        parentGroup.children.push(group);
      } else {
        roots.push(group);
      }
    });
    
    return roots;
  };
  
  // Obtenir les configurations pour un groupe
  const getConfigurationsForGroup = (groupId) => {
    return configurations.filter(config => 
      config.group && (config.group.id === groupId || config.group.id === groupId.toString())
    );
  };
  
  // Rendu récursif des groupes
  const renderGroupTree = (groups, level = 0) => {
    return groups.map(group => {
      const isExpanded = expandedGroups[group.id];
      const groupConfigs = getConfigurationsForGroup(group.id);
      const isSelected = selectedGroup && selectedGroup.id === group.id;
      
      return (
        <div key={group.id} className="mb-1">
          <div 
            className={`
              flex items-center py-2 px-2 rounded-md cursor-pointer
              ${isSelected ? 'bg-blue-100 dark:bg-blue-900/30' : 'hover:bg-gray-100 dark:hover:bg-gray-800'}
            `}
            style={{ paddingLeft: `${(level * 16) + 8}px` }}
            onClick={() => handleSelectGroup(group)}
          >
            <button
              className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 mr-1"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleExpand(group.id);
              }}
            >
              {group.children.length > 0 ? (
                isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />
              ) : (
                <span className="w-4" /> // Espace pour l'alignement
              )}
            </button>
            
            <div className={`
              flex-shrink-0 h-6 w-6 rounded-md flex items-center justify-center mr-2
              ${isSelected 
                ? 'bg-blue-200 dark:bg-blue-800 text-blue-700 dark:text-blue-300' 
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'}
            `}>
              <Folder size={14} />
            </div>
            
            <div className="flex-1 flex items-center justify-between">
              <div>
                <span className="text-gray-900 dark:text-white font-medium">
                  {group.name}
                </span>
                {groupConfigs.length > 0 && (
                  <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                    ({groupConfigs.length})
                  </span>
                )}
              </div>
              
              <div className="flex items-center">
                {!group.isActive && (
                  <StatusBadge
                    status="inactive"
                    customLabel="Inactif"
                    size="xs"
                  />
                )}
                <div className="flex space-x-1 ml-2">
                  <Button
                    variant="text"
                    size="xs"
                    icon={Edit}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditGroup(group);
                    }}
                  />
                  <Button
                    variant="text"
                    size="xs"
                    icon={Trash2}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteGroup(group.id);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          
          {isExpanded && (
            <div className="ml-4">
              {group.children.length > 0 && renderGroupTree(group.children, level + 1)}
              
              {isExpanded && groupConfigs.length > 0 && (
                <div className="mt-1 ml-4">
                  {groupConfigs.map(config => (
                    <div 
                      key={config.key} 
                      className="flex items-center py-1 px-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
                      style={{ paddingLeft: `${(level * 16) + 24}px` }}
                    >
                      <File size={14} className="text-gray-500 dark:text-gray-400 mr-2" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        {config.key}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleSelectGroup = (group) => {
    setSelectedGroup(group);
  };
  
  const handleToggleExpand = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };
  
  const handleCreateGroup = () => {
    const parentPath = selectedGroup ? (selectedGroup.path || '') : '';
    const newPath = parentPath ? `${parentPath}/${Date.now()}` : `/${Date.now()}`;
    
    setGroupForm({
      name: '',
      description: '',
      path: newPath,
      isActive: true,
      parentGroup: selectedGroup
    });
    setFormMode('create');
    setShowGroupModal(true);
  };
  
  const handleEditGroup = (group) => {
    setGroupForm({
      ...group
    });
    setFormMode('edit');
    setShowGroupModal(true);
  };
  
  const handleFormChange = (field, value) => {
    setGroupForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleSubmitGroup = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      let response;
      
      // Préparer les données
      const groupData = { ...groupForm };
      
      // Mettre à jour le chemin si le nom a changé
      if (formMode === 'edit') {
        const pathParts = (groupData.path || '').split('/');
        pathParts[pathParts.length - 1] = groupData.name.toLowerCase().replace(/\s+/g, '-');
        groupData.path = pathParts.join('/');
      }
      
      if (formMode === 'create') {
        // Si c'est un sous-groupe, mettre à jour le chemin
        if (groupData.parentGroup) {
          const parentPath = groupData.parentGroup.path || '';
          const groupPathName = groupData.name.toLowerCase().replace(/\s+/g, '-');
          groupData.path = `${parentPath}/${groupPathName}`;
        } else {
          groupData.path = `/${groupData.name.toLowerCase().replace(/\s+/g, '-')}`;
        }
        
        // Supprimer le champ parentGroup avant l'envoi
        delete groupData.parentGroup;
        
        response = await apiService.config.groups.create(groupData);
        setSuccessMessage('Groupe créé avec succès');
      } else {
        // Supprimer le champ parentGroup avant l'envoi
        delete groupData.parentGroup;
        
        response = await apiService.config.groups.update(groupData.id, groupData);
        setSuccessMessage('Groupe mis à jour avec succès');
      }
      
      // Actualiser les données
      await fetchData();
      
      // Fermer le modal
      setShowGroupModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteGroup = async (groupId) => {
    // Vérifier s'il y a des configurations dans ce groupe
    const groupConfigs = getConfigurationsForGroup(groupId);
    
    if (groupConfigs.length > 0) {
      setError(`Ce groupe contient ${groupConfigs.length} configuration(s). Veuillez les déplacer ou les supprimer avant de supprimer le groupe.`);
      return;
    }
    
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce groupe ? Cette action est irréversible.')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.config.groups.delete(groupId);
      
      // Actualiser les données
      await fetchData();
      
      // Désélectionner le groupe s'il était sélectionné
      if (selectedGroup && selectedGroup.id === groupId) {
        setSelectedGroup(null);
      }
      
      setSuccessMessage('Groupe supprimé avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer le groupe.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    await fetchData();
  };
  
  // Colonnes pour les configurations du groupe sélectionné
  const configColumns = [
    {
      key: 'key',
      header: 'Clé',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-8 w-8 bg-blue-100 dark:bg-blue-900/30 rounded-md flex items-center justify-center text-blue-600 dark:text-blue-300">
            <File size={16} />
          </div>
          <div className="ml-3">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.key}
            </div>
          </div>
        </div>
      )
    },
    {
      key: 'description',
      header: 'Description',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300 max-w-md truncate">
          {row.description || <span className="text-gray-400 dark:text-gray-500">Aucune description</span>}
        </div>
      )
    },
    {
      key: 'defaultValue',
      header: 'Valeur par défaut',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.defaultValue ? (
            <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded font-mono text-sm">
              {row.defaultValue.length > 20 
                ? `${row.defaultValue.substring(0, 20)}...` 
                : row.defaultValue}
            </code>
          ) : (
            <span className="text-gray-400 dark:text-gray-500">Non défini</span>
          )}
        </div>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      render: (row) => (
        <StatusBadge
          status={row.isActive ? 'active' : 'inactive'}
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      )
    }
  ];
  
  const groupTree = buildGroupTree();
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <FolderTree className="h-6 w-6 mr-2 text-green-500" />
            Groupes de configuration
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Organisez vos configurations en groupes hiérarchiques
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={FolderPlus}
            onClick={handleCreateGroup}
          >
            Nouveau groupe
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex items-center">
          <div className="flex-1 relative">
            <input
              type="text"
              className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Rechercher un groupe..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
      
      {/* Contenu principal en deux colonnes */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Arborescence des groupes */}
        <div className="col-span-1">
          <Card className="h-full overflow-hidden">
            <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Structure des groupes
              </h3>
              <div className="flex space-x-2">
                <button
                  className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                  onClick={() => {
                    // Tout développer
                    const expanded = {};
                    groups.forEach(group => {
                      expanded[group.id] = true;
                    });
                    setExpandedGroups(expanded);
                  }}
                >
                  <ChevronDown size={16} />
                </button>
                <button
                  className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                  onClick={() => {
                    // Tout réduire
                    const expanded = {};
                    groups.forEach(group => {
                      expanded[group.id] = false;
                    });
                    setExpandedGroups(expanded);
                  }}
                >
                  <ChevronRight size={16} />
                </button>
              </div>
            </div>
            <div className="p-4 overflow-y-auto max-h-[calc(100vh-300px)]">
              {loading ? (
                <div className="flex justify-center items-center py-8">
                  <RefreshCw className="h-8 w-8 text-gray-400 animate-spin" />
                </div>
              ) : groups.length === 0 ? (
                <div className="text-center py-8">
                  <FolderX className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  <p className="text-gray-500 dark:text-gray-400">
                    Aucun groupe trouvé
                  </p>
                  <Button
                    variant="outline"
                    size="sm"
                    icon={FolderPlus}
                    onClick={handleCreateGroup}
                    className="mt-4"
                  >
                    Créer un groupe
                  </Button>
                </div>
              ) : (
                <div className="space-y-1">
                  {renderGroupTree(groupTree)}
                </div>
              )}
            </div>
          </Card>
        </div>
        
        {/* Détails du groupe sélectionné et configurations */}
        <div className="col-span-1 lg:col-span-2">
          <Card className="h-full overflow-hidden">
            {selectedGroup ? (
              <div className="h-full flex flex-col">
                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                        <Folder className="h-5 w-5 mr-2 text-green-500" />
                        {selectedGroup.name}
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400 mt-1">
                        {selectedGroup.description || 'Aucune description'}
                      </p>
                      <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-medium">Chemin: </span>
                        <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded">
                          {selectedGroup.path || '/'}
                        </code>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        variant="outline"
                        size="sm"
                        icon={Edit}
                        onClick={() => handleEditGroup(selectedGroup)}
                      >
                        Éditer
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        icon={FolderPlus}
                        onClick={handleCreateGroup}
                      >
                        Sous-groupe
                      </Button>
                    </div>
                  </div>
                </div>
                
                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                  <h4 className="text-md font-medium text-gray-900 dark:text-white">
                    Configurations dans ce groupe
                  </h4>
                </div>
                
                <div className="flex-1 overflow-y-auto">
                  <DataGrid
                    data={getConfigurationsForGroup(selectedGroup.id)}
                    columns={configColumns}
                    loading={loading}
                    emptyMessage="Aucune configuration dans ce groupe."
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-full p-8 text-center">
                <FolderTree className="h-16 w-16 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Sélectionnez un groupe
                </h3>
                <p className="text-gray-500 dark:text-gray-400 max-w-md">
                  Sélectionnez un groupe dans l'arborescence pour voir ses détails et les configurations associées.
                </p>
                <Button
                  variant="outline"
                  size="sm"
                  icon={FolderPlus}
                  onClick={handleCreateGroup}
                  className="mt-4"
                >
                  Créer un groupe
                </Button>
              </div>
            )}
          </Card>
        </div>
      </div>
      
      {/* Modal de création/édition de groupe */}
      {showGroupModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' 
                  ? formMode.parentGroup 
                    ? 'Nouveau sous-groupe' 
                    : 'Nouveau groupe' 
                  : 'Modifier le groupe'
                }
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowGroupModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitGroup} className="p-6 space-y-4">
              {/* Nom */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nom *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={groupForm.name}
                  onChange={(e) => handleFormChange('name', e.target.value)}
                  required
                />
              </div>
              
              {/* Description */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={groupForm.description || ''}
                  onChange={(e) => handleFormChange('description', e.target.value)}
                  rows={3}
                />
              </div>
              
              {/* Chemin */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Chemin
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                  value={groupForm.path || '/'}
                  readOnly
                />
                <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                  Le chemin est généré automatiquement à partir du nom du groupe et de sa position dans la hiérarchie.
                </p>
              </div>
              
              {/* Parent */}
              {formMode === 'create' && groupForm.parentGroup && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Groupe parent
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                    value={groupForm.parentGroup.name}
                    readOnly
                  />
                </div>
              )}
              
              {/* Activé/Désactivé */}
              <div className="flex items-center">
                <input
                  id="group-active"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={groupForm.isActive}
                  onChange={(e) => handleFormChange('isActive', e.target.checked)}
                />
                <label htmlFor="group-active" className="ml-2 block text-sm text-gray-900 dark:text-white">
                  Groupe actif
                </label>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowGroupModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigurationGroupsManagement;
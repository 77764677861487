import React, { useState, useEffect, useMemo } from 'react';
import {
  Database,
  PlusCircle,
  Edit,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  Tag,
  Settings,
  FileText,
  Hash,
  Filter,
  ChevronDown,
  Eye,
  EyeOff,
  MoreHorizontal,
  ArrowUp,
  ArrowDown,
  Check,
  Sliders,
  Copy,
  Package,
  Star,
  StarOff
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const ConfigurationsManagement = () => {
  // États de données
  const [configurations, setConfigurations] = useState([]);
  const [configGroups, setConfigGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedScope, setSelectedScope] = useState('all');
  const [showMandatoryOnly, setShowMandatoryOnly] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [sortField, setSortField] = useState('key');
  const [sortDirection, setSortDirection] = useState('asc');
  
  // États pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pageSizeOptions = [5, 10, 20, 50, 100];
  
  // États pour les formulaires
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [configForm, setConfigForm] = useState({
    key: '',
    description: '',
    scope: 'GLOBAL',
    group: null,
    isMandatory: false,
    isActive: true,
    defaultValue: '',
    validationRule: ''
  });
  
  // État pour visualiser une configuration
  const [viewConfig, setViewConfig] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  
  // Chargement initial des configurations et des groupes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [configurationsData, groupsData] = await Promise.all([
          apiService.config.configurations.getAll(),
          apiService.config.groups.getAll()
        ]);
        
        setConfigurations(configurationsData || []);
        setConfigGroups(groupsData || []);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des données:', err);
        setError('Impossible de charger les données. Veuillez réessayer.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  // Filtrage et tri des configurations
  const filteredAndSortedConfigurations = useMemo(() => {
    let result = [...configurations];
    
    // Application des filtres
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(config => 
        (config.key && config.key.toLowerCase().includes(searchLower)) ||
        (config.description && config.description.toLowerCase().includes(searchLower)) ||
        (config.defaultValue && config.defaultValue.toLowerCase().includes(searchLower))
      );
    }
    
    if (selectedGroup !== 'all') {
      result = result.filter(config => 
        config.group && (config.group.id?.toString() === selectedGroup || 
                         config.group.name === selectedGroup)
      );
    }
    
    if (selectedStatus !== 'all') {
      const isActive = selectedStatus === 'active';
      result = result.filter(config => config.isActive === isActive);
    }
    
    if (selectedScope !== 'all') {
      result = result.filter(config => config.scope === selectedScope);
    }
    
    if (showMandatoryOnly) {
      result = result.filter(config => config.isMandatory);
    }
    
    // Application du tri
    result.sort((a, b) => {
      let valA, valB;
      
      // Déterminer les valeurs à comparer selon le champ de tri
      switch (sortField) {
        case 'key':
          valA = a.key?.toLowerCase() || '';
          valB = b.key?.toLowerCase() || '';
          break;
        case 'group':
          valA = a.group?.name?.toLowerCase() || '';
          valB = b.group?.name?.toLowerCase() || '';
          break;
        case 'scope':
          valA = a.scope || '';
          valB = b.scope || '';
          break;
        case 'status':
          valA = a.isActive ? 1 : 0;
          valB = b.isActive ? 1 : 0;
          break;
        default:
          valA = a[sortField] || '';
          valB = b[sortField] || '';
      }
      
      // Appliquer la direction du tri
      if (sortDirection === 'asc') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    });
    
    return result;
  }, [
    configurations, 
    searchTerm, 
    selectedGroup, 
    selectedStatus, 
    selectedScope, 
    showMandatoryOnly,
    sortField,
    sortDirection
  ]);
  
  // Pagination
  const paginatedConfigurations = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedConfigurations.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedConfigurations, currentPage, itemsPerPage]);
  
  const totalPages = useMemo(() => 
    Math.ceil(filteredAndSortedConfigurations.length / itemsPerPage)
  , [filteredAndSortedConfigurations, itemsPerPage]);
  
  // Gestionnaire de tri
  const handleSort = (field) => {
    if (sortField === field) {
      // Inverser la direction si le même champ est cliqué
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Nouveau champ de tri, définir à ascendant par défaut
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Rendu du header de colonne avec support de tri
  const renderSortableHeader = (field, label) => (
    <div 
      className="flex items-center cursor-pointer select-none"
      onClick={() => handleSort(field)}
    >
      {label}
      {sortField === field && (
        <span className="ml-1">
          {sortDirection === 'asc' ? <ArrowUp size={14} /> : <ArrowDown size={14} />}
        </span>
      )}
    </div>
  );
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'key',
      header: () => renderSortableHeader('key', 'Clé'),
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-gradient-to-br from-blue-100 to-blue-200 dark:from-blue-900/30 dark:to-blue-800/30 rounded-lg flex items-center justify-center text-blue-600 dark:text-blue-300 shadow-sm">
            <Hash size={18} />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.key}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {row.scope}
            </div>
          </div>
          {row.isMandatory && (
            <div className="ml-2">
              <Star size={14} className="text-amber-500" />
            </div>
          )}
        </div>
      )
    },
    {
      key: 'description',
      header: 'Description',
      sortable: true,
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300 max-w-md truncate">
          {row.description || <span className="text-gray-400 dark:text-gray-500 italic">Aucune description</span>}
        </div>
      )
    },
    {
      key: 'group',
      header: () => renderSortableHeader('group', 'Groupe'),
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          {row.group ? (
            <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-purple-100 to-purple-200 text-purple-800 dark:from-purple-900/30 dark:to-purple-800/30 dark:text-purple-300 shadow-sm">
              <Tag className="w-3 h-3 mr-1" />
              {row.group.name}
            </span>
          ) : (
            <span className="text-gray-400 dark:text-gray-500 italic">Non classé</span>
          )}
        </div>
      )
    },
    {
      key: 'defaultValue',
      header: 'Valeur par défaut',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300">
          {row.defaultValue !== undefined && row.defaultValue !== null ? (
            <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded font-mono text-sm">
              {row.defaultValue.length > 20 
                ? `${row.defaultValue.substring(0, 20)}...` 
                : row.defaultValue}
            </code>
          ) : (
            <span className="text-gray-400 dark:text-gray-500 italic">Non défini</span>
          )}
        </div>
      )
    },
    {
      key: 'status',
      header: () => renderSortableHeader('status', 'Statut'),
      sortable: true,
      render: (row) => (
        <div className="flex flex-col space-y-1">
          <StatusBadge
            status={row.isActive ? 'active' : 'inactive'}
            customLabel={row.isActive ? 'Actif' : 'Inactif'}
          />
          {row.isMandatory && (
            <StatusBadge
              status="warning"
              customLabel="Obligatoire"
              size="sm"
            />
          )}
        </div>
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end items-center space-x-1">
          <Button
            variant="text"
            size="sm"
            icon={Eye}
            tooltip="Voir les détails"
            onClick={() => handleViewConfig(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            tooltip="Modifier"
            onClick={() => handleEditConfig(row)}
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            tooltip="Supprimer"
            onClick={() => handleDeleteConfig(row.key)}
          />
          <div className="relative group">
            <Button
              variant="text"
              size="sm"
              icon={MoreHorizontal}
              tooltip="Plus d'actions"
              onClick={(e) => e.currentTarget.focus()}
            />
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg overflow-hidden z-20 hidden group-hover:block group-focus-within:block">
              <div className="py-1">
                <button
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                  onClick={() => handleCopyConfig(row)}
                >
                  <Copy size={14} className="mr-2" />
                  Dupliquer
                </button>
                {row.isActive ? (
                  <button
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                    onClick={() => handleToggleStatus(row, false)}
                  >
                    <StarOff size={14} className="mr-2" />
                    Désactiver
                  </button>
                ) : (
                  <button
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                    onClick={() => handleToggleStatus(row, true)}
                  >
                    <Star size={14} className="mr-2" />
                    Activer
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Réinitialiser à la première page lors d'une recherche
  };
  
  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
    setCurrentPage(1); // Réinitialiser à la première page lors d'un changement de groupe
  };
  
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
  };
  
  const handleScopeChange = (e) => {
    setSelectedScope(e.target.value);
    setCurrentPage(1);
  };
  
  const handleMandatoryChange = (e) => {
    setShowMandatoryOnly(e.target.checked);
    setCurrentPage(1);
  };
  
  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedGroup('all');
    setSelectedStatus('all');
    setSelectedScope('all');
    setShowMandatoryOnly(false);
    setCurrentPage(1);
  };
  
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Réinitialiser à la première page lors d'un changement d'items par page
  };
  
  const handleViewConfig = (config) => {
    setViewConfig(config);
    setShowViewModal(true);
  };
  
  const handleCreateConfig = () => {
    setConfigForm({
      key: '',
      description: '',
      scope: 'GLOBAL',
      group: null,
      isMandatory: false,
      isActive: true,
      defaultValue: '',
      validationRule: ''
    });
    setFormMode('create');
    setShowConfigModal(true);
  };
  
  const handleEditConfig = (config) => {
    setConfigForm({
      ...config,
      // Si le group est un objet avec un id, on conserve seulement l'id pour le formulaire
      group: config.group ? config.group.id : null
    });
    setFormMode('edit');
    setShowConfigModal(true);
  };
  
  const handleCopyConfig = (config) => {
    setConfigForm({
      ...config,
      key: `${config.key}_copy`,
      group: config.group ? config.group.id : null
    });
    setFormMode('create');
    setShowConfigModal(true);
  };
  
  const handleToggleStatus = async (config, status) => {
    try {
      const updatedConfig = { ...config, isActive: status };
      await apiService.config.configurations.update(config.key, updatedConfig);
      
      // Actualiser les configurations
      const updatedConfigurations = await apiService.config.configurations.getAll();
      setConfigurations(updatedConfigurations || []);
      
      setSuccessMessage(`Configuration ${status ? 'activée' : 'désactivée'} avec succès`);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la mise à jour du statut:', err);
      setError(err.message || 'Une erreur est survenue lors de la mise à jour du statut.');
    }
  };
  
  const handleFormChange = (field, value) => {
    setConfigForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleSubmitConfig = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      // Préparation des données
      const configData = {
        ...configForm,
      };
      
      // Si le groupe est un id, on le transforme en objet avec un id
      if (configData.group && typeof configData.group === 'string') {
        configData.group = { id: configData.group };
      }
      
      let response;
      
      if (formMode === 'create') {
        response = await apiService.config.configurations.create(configData);
        setSuccessMessage('Configuration créée avec succès');
      } else {
        response = await apiService.config.configurations.update(configData.key, configData);
        setSuccessMessage('Configuration mise à jour avec succès');
      }
      
      // Actualiser la liste des configurations
      const updatedConfigurations = await apiService.config.configurations.getAll();
      setConfigurations(updatedConfigurations || []);
      
      // Fermer le modal
      setShowConfigModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteConfig = async (key) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette configuration ? Cette action est irréversible.')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.config.configurations.delete(key);
      
      // Actualiser la liste des configurations
      const updatedConfigurations = await apiService.config.configurations.getAll();
      setConfigurations(updatedConfigurations || []);
      
      setSuccessMessage('Configuration supprimée avec succès');
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || 'Impossible de supprimer la configuration.');
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const [configurationsData, groupsData] = await Promise.all([
        apiService.config.configurations.getAll(),
        apiService.config.groups.getAll()
      ]);
      
      setConfigurations(configurationsData || []);
      setConfigGroups(groupsData || []);
    } catch (err) {
      console.error('Erreur lors de l\'actualisation:', err);
      setError('Impossible d\'actualiser les données.');
    } finally {
      setLoading(false);
    }
  };
  
  // Rendu du paginateur amélioré
  const renderPagination = () => {
    if (filteredAndSortedConfigurations.length === 0) return null;
    
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Afficher
          </span>
          <select
            className="text-sm border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {pageSizeOptions.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            éléments par page
          </span>
        </div>
        
        <div className="flex items-center">
          <span className="text-sm text-gray-500 dark:text-gray-400 mr-4">
            {currentPage} sur {totalPages} pages 
            ({filteredAndSortedConfigurations.length} configurations)
          </span>
          
          <div className="flex space-x-1">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              &laquo;
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(curr => Math.max(1, curr - 1))}
              disabled={currentPage === 1}
            >
              &lsaquo;
            </Button>
            
            {/* Pages visibles */}
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              // Logique pour montrer les pages autour de la page courante
              let pageNum;
              if (totalPages <= 5) {
                pageNum = i + 1;
              } else if (currentPage <= 3) {
                pageNum = i + 1;
              } else if (currentPage >= totalPages - 2) {
                pageNum = totalPages - 4 + i;
              } else {
                pageNum = currentPage - 2 + i;
              }
              
              if (pageNum <= totalPages) {
                return (
                  <Button
                    key={pageNum}
                    variant={currentPage === pageNum ? "primary" : "outline"}
                    size="sm"
                    onClick={() => setCurrentPage(pageNum)}
                  >
                    {pageNum}
                  </Button>
                );
              }
              return null;
            })}
            
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(curr => Math.min(totalPages, curr + 1))}
              disabled={currentPage === totalPages}
            >
              &rsaquo;
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Settings className="h-6 w-6 mr-2 text-blue-500" />
            Gestion des configurations
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les paramètres et configurations du système
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant="outline"
            icon={Filter}
            onClick={() => setShowFilters(!showFilters)}
            className={showFilters ? 'border-blue-500 text-blue-600 dark:text-blue-400' : ''}
          >
            Filtres
            {showFilters ? <ChevronDown className="ml-1 h-4 w-4" /> : <ChevronDown className="ml-1 h-4 w-4" />}
          </Button>
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={handleCreateConfig}
          >
            Nouvelle configuration
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-gradient-to-r from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-800/20 border-l-4 border-green-500 p-4 rounded-md shadow-sm animate-fadeIn">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-gradient-to-r from-red-50 to-red-100 dark:from-red-900/20 dark:to-red-800/20 border-l-4 border-red-500 p-4 rounded-md shadow-sm animate-fadeIn">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <Card>
        {/* Barre de recherche toujours visible */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="relative">
            <input
              type="text"
              className="w-full p-3 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Rechercher par clé, description, valeur..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            {searchTerm && (
              <button
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              </button>
            )}
          </div>
        </div>
        
        {/* Filtres avancés développables */}
        <div 
          className={`${
            showFilters ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          } transition-all duration-300 ease-in-out`}
        >
          <div className="p-4 space-y-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                <Sliders className="h-5 w-5 mr-2 text-gray-500" />
                Filtres avancés
              </h3>
              <Button
                variant="text"
                size="sm"
                onClick={handleResetFilters}
              >
                Réinitialiser les filtres
              </Button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Filtre par groupe */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Groupe
                </label>
                <select
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={selectedGroup}
                  onChange={handleGroupChange}
                >
                  <option value="all">Tous les groupes</option>
                  {configGroups.map(group => (
                    <option key={group.id} value={group.id}>{group.name}</option>
                  ))}
                </select>
              </div>
              
              {/* Filtre par statut */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Statut
                </label>
                <select
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="all">Tous les statuts</option>
                  <option value="active">Actif</option>
                  <option value="inactive">Inactif</option>
                </select>
              </div>
              
              {/* Filtre par scope */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Portée
                </label>
                <select
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={selectedScope}
                  onChange={handleScopeChange}
                >
                  <option value="all">Toutes les portées</option>
                  <option value="GLOBAL">Global</option>
                  <option value="ENVIRONMENT">Environnement</option>
                  <option value="TENANT">Tenant</option>
                  <option value="APPLICATION">Application</option>
                </select>
              </div>
            </div>
            
            {/* Options supplémentaires */}
            <div className="flex items-center mt-2">
              <input
                id="show-mandatory"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                checked={showMandatoryOnly}
                onChange={handleMandatoryChange}
              />
              <label htmlFor="show-mandatory" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                Afficher uniquement les configurations obligatoires
              </label>
            </div>
            
            {/* Statistiques de filtrage */}
            {filteredAndSortedConfigurations.length !== configurations.length && (
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                <span className="font-medium">{filteredAndSortedConfigurations.length}</span> configuration(s) sur <span className="font-medium">{configurations.length}</span> correspondent aux filtres.
              </div>
            )}
          </div>
        </div>
        
        {/* Résumé des filtres actifs */}
        {(selectedGroup !== 'all' || selectedStatus !== 'all' || selectedScope !== 'all' || showMandatoryOnly || searchTerm) && (
          <div className="p-3 bg-gray-50 dark:bg-gray-800/50 flex flex-wrap gap-2 items-center">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Filtres actifs:
            </span>
            
            {searchTerm && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300">
                Recherche: {searchTerm}
                <button 
                  className="ml-1 text-blue-600 hover:text-blue-800 dark:text-blue-400"
                  onClick={() => setSearchTerm('')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {selectedGroup !== 'all' && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300">
                Groupe: {configGroups.find(g => g.id.toString() === selectedGroup)?.name || selectedGroup}
                <button 
                  className="ml-1 text-purple-600 hover:text-purple-800 dark:text-purple-400"
                  onClick={() => setSelectedGroup('all')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {selectedStatus !== 'all' && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300">
                Statut: {selectedStatus === 'active' ? 'Actif' : 'Inactif'}
                <button 
                  className="ml-1 text-green-600 hover:text-green-800 dark:text-green-400"
                  onClick={() => setSelectedStatus('all')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {selectedScope !== 'all' && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900/30 dark:text-indigo-300">
                Portée: {selectedScope}
                <button 
                  className="ml-1 text-indigo-600 hover:text-indigo-800 dark:text-indigo-400"
                  onClick={() => setSelectedScope('all')}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            {showMandatoryOnly && (
              <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300">
                Obligatoires uniquement
                <button 
                  className="ml-1 text-amber-600 hover:text-amber-800 dark:text-amber-400"
                  onClick={() => setShowMandatoryOnly(false)}
                >
                  <X size={14} />
                </button>
              </span>
            )}
            
            <button 
              className="ml-auto text-xs text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 underline"
              onClick={handleResetFilters}
            >
              Effacer tous les filtres
            </button>
          </div>
        )}
      </Card>
      
      {/* Liste des configurations */}
      <Card>
        <div className="overflow-hidden">
          <DataGrid
            data={paginatedConfigurations}
            columns={columns}
            loading={loading}
            emptyMessage={
              <div className="flex flex-col items-center justify-center py-12">
                <Package className="h-16 w-16 text-gray-300 dark:text-gray-600 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Aucune configuration trouvée
                </h3>
                <p className="text-gray-500 dark:text-gray-400 text-center max-w-md mb-6">
                  {searchTerm || selectedGroup !== 'all' || selectedStatus !== 'all' || selectedScope !== 'all' || showMandatoryOnly
                    ? "Aucune configuration ne correspond aux critères de recherche."
                    : "Aucune configuration n'a été créée. Commencez par en créer une."}
                </p>
                {!(searchTerm || selectedGroup !== 'all' || selectedStatus !== 'all' || selectedScope !== 'all' || showMandatoryOnly) && (
                  <Button
                    variant="primary"
                    icon={PlusCircle}
                    onClick={handleCreateConfig}
                  >
                    Créer une configuration
                  </Button>
                )}
              </div>
            }
            hoverEffect
            className="rounded-lg"
          />
          
          {/* Pagination */}
          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
            {renderPagination()}
          </div>
        </div>
      </Card>
      
      {/* Modal de création/édition de configuration */}
      {showConfigModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4 animate-fadeIn">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl overflow-hidden transform transition-all">
            <div className="flex justify-between items-center bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-700 dark:to-gray-800 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' ? 'Nouvelle configuration' : 'Modifier la configuration'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                onClick={() => setShowConfigModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitConfig} className="p-6 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Clé de configuration */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Clé *
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.key}
                    onChange={(e) => handleFormChange('key', e.target.value)}
                    required
                    disabled={formMode === 'edit'} // Ne pas permettre l'édition de la clé
                  />
                  {formMode === 'edit' && (
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      La clé de configuration ne peut pas être modifiée après création.
                    </p>
                  )}
                </div>
                
                {/* Groupe */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Groupe
                  </label>
                  <select
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.group || ''}
                    onChange={(e) => handleFormChange('group', e.target.value || null)}
                  >
                    <option value="">Aucun groupe</option>
                    {configGroups.map(group => (
                      <option key={group.id} value={group.id}>{group.name}</option>
                    ))}
                  </select>
                </div>
                
                {/* Description */}
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.description || ''}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                    rows={3}
                  />
                </div>
                
                {/* Scope */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Portée *
                  </label>
                  <select
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.scope}
                    onChange={(e) => handleFormChange('scope', e.target.value)}
                    required
                  >
                    <option value="GLOBAL">Globale</option>
                    <option value="ENVIRONMENT">Environnement</option>
                    <option value="TENANT">Tenant</option>
                    <option value="APPLICATION">Application</option>
                  </select>
                </div>
                
                {/* Valeur par défaut */}
                <div className="col-span-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Valeur par défaut
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.defaultValue || ''}
                    onChange={(e) => handleFormChange('defaultValue', e.target.value)}
                  />
                </div>
                
                {/* Règle de validation */}
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Règle de validation
                  </label>
                  <input
                    type="text"
                    className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                    value={configForm.validationRule || ''}
                    onChange={(e) => handleFormChange('validationRule', e.target.value)}
                    placeholder="ex: ^[0-9]+$ (regex)"
                  />
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Expression régulière pour valider les valeurs. Laissez vide pour aucune validation.
                  </p>
                </div>
              </div>
              
              {/* Options supplémentaires */}
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start gap-3 p-3 bg-gray-50 dark:bg-gray-800 rounded-md">
                <div className="flex items-center">
                  <input
                    id="config-mandatory"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={configForm.isMandatory}
                    onChange={(e) => handleFormChange('isMandatory', e.target.checked)}
                  />
                  <label htmlFor="config-mandatory" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                    Configuration obligatoire
                  </label>
                </div>
                
                <div className="flex items-center">
                  <input
                    id="config-active"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={configForm.isActive}
                    onChange={(e) => handleFormChange('isActive', e.target.checked)}
                  />
                  <label htmlFor="config-active" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                    Configuration active
                  </label>
                </div>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowConfigModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      
      {/* Modal de visualisation de configuration */}
      {showViewModal && viewConfig && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4 animate-fadeIn">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl overflow-hidden">
            <div className="flex justify-between items-center bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/30 dark:to-blue-800/30 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                <Hash className="h-5 w-5 mr-2 text-blue-600 dark:text-blue-400" />
                {viewConfig.key}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowViewModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Clé</h4>
                    <p className="mt-1 text-base text-gray-900 dark:text-white font-mono bg-gray-100 dark:bg-gray-700 p-2 rounded">
                      {viewConfig.key}
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Groupe</h4>
                    <p className="mt-1 text-base text-gray-900 dark:text-white">
                      {viewConfig.group ? viewConfig.group.name : "Non classé"}
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Portée</h4>
                    <p className="mt-1">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300">
                        {viewConfig.scope}
                      </span>
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Statut</h4>
                    <div className="mt-1 flex space-x-2">
                      <StatusBadge
                        status={viewConfig.isActive ? 'active' : 'inactive'}
                        customLabel={viewConfig.isActive ? 'Actif' : 'Inactif'}
                      />
                      {viewConfig.isMandatory && (
                        <StatusBadge
                          status="warning"
                          customLabel="Obligatoire"
                        />
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Description</h4>
                    <p className="mt-1 text-base text-gray-900 dark:text-white">
                      {viewConfig.description || <span className="text-gray-400 dark:text-gray-500 italic">Aucune description</span>}
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Valeur par défaut</h4>
                    <p className="mt-1">
                      {viewConfig.defaultValue ? (
                        <code className="block p-2 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white font-mono text-sm rounded overflow-auto max-h-20">
                          {viewConfig.defaultValue}
                        </code>
                      ) : (
                        <span className="text-gray-400 dark:text-gray-500 italic">Non défini</span>
                      )}
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">Règle de validation</h4>
                    <p className="mt-1">
                      {viewConfig.validationRule ? (
                        <code className="block p-2 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white font-mono text-sm rounded overflow-auto max-h-20">
                          {viewConfig.validationRule}
                        </code>
                      ) : (
                        <span className="text-gray-400 dark:text-gray-500 italic">Aucune règle de validation</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="mt-6 border-t border-gray-200 dark:border-gray-700 pt-6 flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => setShowViewModal(false)}
                >
                  Fermer
                </Button>
                <Button
                  variant="primary"
                  icon={Edit}
                  onClick={() => {
                    setShowViewModal(false);
                    handleEditConfig(viewConfig);
                  }}
                >
                  Modifier
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigurationsManagement;
import React, { useState, useEffect } from 'react';
import {
  Globe,
  PlusCircle,
  Edit,
  Trash2,
  Search,
  RefreshCw,
  CheckCircle,
  AlertTriangle,
  X,
  Save,
  PlayCircle,
  PauseCircle,
  Copy,
  Server
} from 'lucide-react';

import Card from '../../components/shared/Card';
import Button from '../../components/shared/Button';
import DataGrid from '../../components/shared/DataGrid';
import StatusBadge from '../../components/shared/StatusBadge';
import DateFormatter from '../../components/shared/DateFormatter';
import apiService from '../../services/api.service';

const EnvironmentsManagement = () => {
  // États de données
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // États pour les formulaires
  const [showEnvModal, setShowEnvModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' ou 'edit'
  const [envForm, setEnvForm] = useState({
    code: '',
    name: '',
    description: '',
    type: 'DEVELOPMENT',
    url: '',
    isActive: true,
    priority: 0
  });
  const [copyForm, setCopyForm] = useState({
    sourceEnv: '',
    targetEnv: ''
  });
  
  // Chargement initial des environnements
  useEffect(() => {
    fetchEnvironments();
  }, []);
  
  const fetchEnvironments = async () => {
    setLoading(true);
    try {
      const environmentsData = await apiService.config.environments.getAll();
      setEnvironments(environmentsData || []);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement des environnements:', err);
      setError('Impossible de charger les environnements. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrage des environnements basé sur la recherche
  const filteredEnvironments = environments.filter(env => {
    const searchableValues = [
      env.code,
      env.name,
      env.description,
      env.type
    ].map(val => (val || '').toLowerCase());
    
    return searchTerm === '' || searchableValues.some(val => val.includes(searchTerm.toLowerCase()));
  });
  
  // Configuration des colonnes pour le DataGrid
  const columns = [
    {
      key: 'code',
      header: 'Code',
      sortable: true,
      render: (row) => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center text-indigo-600 dark:text-indigo-300">
            <Server size={18} />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900 dark:text-white">
              {row.code}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {row.name}
            </div>
          </div>
        </div>
      )
    },
    {
      key: 'type',
      header: 'Type',
      sortable: true,
      render: (row) => {
        let color;
        switch (row.type) {
          case 'DEVELOPMENT':
            color = 'blue';
            break;
          case 'TESTING':
            color = 'yellow';
            break;
          case 'STAGING':
            color = 'purple';
            break;
          case 'PRODUCTION':
            color = 'green';
            break;
          case 'DR':
            color = 'red';
            break;
          default:
            color = 'gray';
        }
        
        return (
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color}-100 text-${color}-800 dark:bg-${color}-900/30 dark:text-${color}-300`}>
            {row.type}
          </span>
        );
      }
    },
    {
      key: 'description',
      header: 'Description',
      render: (row) => (
        <div className="text-gray-700 dark:text-gray-300 max-w-md truncate">
          {row.description || <span className="text-gray-400 dark:text-gray-500">Aucune description</span>}
        </div>
      )
    },
    {
      key: 'status',
      header: 'Statut',
      sortable: true,
      render: (row) => (
        <StatusBadge
          status={row.isActive ? 'active' : 'inactive'}
          customLabel={row.isActive ? 'Actif' : 'Inactif'}
        />
      )
    },
    {
      key: 'priority',
      header: 'Priorité',
      sortable: true,
      render: (row) => (
        <div className="text-center">
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
            {row.priority || 0}
          </span>
        </div>
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      align: 'right',
      render: (row) => (
        <div className="flex justify-end space-x-2">
          {row.isActive ? (
            <Button
              variant="text"
              size="sm"
              icon={PauseCircle}
              onClick={() => handleDeactivateEnv(row.code)}
              title="Désactiver"
            />
          ) : (
            <Button
              variant="text"
              size="sm"
              icon={PlayCircle}
              onClick={() => handleActivateEnv(row.code)}
              title="Activer"
            />
          )}
          <Button
            variant="text"
            size="sm"
            icon={Edit}
            onClick={() => handleEditEnv(row)}
            title="Modifier"
          />
          <Button
            variant="text"
            size="sm"
            icon={Trash2}
            onClick={() => handleDeleteEnv(row.code)}
            title="Supprimer"
          />
        </div>
      )
    }
  ];
  
  // Gestionnaires d'événements
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleCreateEnv = () => {
    setEnvForm({
      code: '',
      name: '',
      description: '',
      type: 'DEVELOPMENT',
      url: '',
      isActive: true,
      priority: 0
    });
    setFormMode('create');
    setShowEnvModal(true);
  };
  
  const handleOpenCopyModal = () => {
    if (environments.length < 2) {
      setError('Vous devez avoir au moins deux environnements pour effectuer une copie.');
      return;
    }
    
    setCopyForm({
      sourceEnv: environments[0]?.code || '',
      targetEnv: environments[1]?.code || ''
    });
    setShowCopyModal(true);
  };
  
  const handleEditEnv = (env) => {
    setEnvForm({
      ...env
    });
    setFormMode('edit');
    setShowEnvModal(true);
  };
  
  const handleFormChange = (field, value) => {
    setEnvForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleCopyFormChange = (field, value) => {
    setCopyForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const handleSubmitEnv = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);
    
    try {
      let response;
      
      if (formMode === 'create') {
        response = await apiService.config.environments.create(envForm);
        setSuccessMessage('Environnement créé avec succès');
      } else {
        response = await apiService.config.environments.update(envForm.code, envForm);
        setSuccessMessage('Environnement mis à jour avec succès');
      }
      
      // Actualiser la liste des environnements
      await fetchEnvironments();
      
      // Fermer le modal
      setShowEnvModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la soumission:', err);
      setError(err.message || 'Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubmitCopy = async (e) => {
    e.preventDefault();
    
    if (copyForm.sourceEnv === copyForm.targetEnv) {
      setError('Les environnements source et cible ne peuvent pas être identiques.');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await apiService.config.environments.copyConfiguration(
        copyForm.sourceEnv, 
        copyForm.targetEnv
      );
      
      setSuccessMessage(`Configuration copiée avec succès de ${copyForm.sourceEnv} vers ${copyForm.targetEnv}`);
      
      // Fermer le modal
      setShowCopyModal(false);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la copie:', err);
      setError(err.message || 'Impossible de copier la configuration.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleActivateEnv = async (code) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.config.environments.activate(code);
      
      // Actualiser la liste des environnements
      await fetchEnvironments();
      
      setSuccessMessage(`Environnement ${code} activé avec succès`);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de l\'activation:', err);
      setError(err.message || `Impossible d'activer l'environnement ${code}.`);
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeactivateEnv = async (code) => {
    setLoading(true);
    setError(null);
    
    try {
      await apiService.config.environments.deactivate(code);
      
      // Actualiser la liste des environnements
      await fetchEnvironments();
      
      setSuccessMessage(`Environnement ${code} désactivé avec succès`);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la désactivation:', err);
      setError(err.message || `Impossible de désactiver l'environnement ${code}.`);
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteEnv = async (code) => {
    if (!window.confirm(`Êtes-vous sûr de vouloir supprimer l'environnement ${code} ? Cette action est irréversible.`)) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Note: Il semble qu'il n'y ait pas d'API de suppression dans votre service, 
      // mais elle serait appelée comme ceci:
      // await apiService.config.environments.delete(code);
      
      // Actualiser la liste des environnements
      await fetchEnvironments();
      
      setSuccessMessage(`Environnement ${code} supprimé avec succès`);
      
      // Effacer le message de succès après 3 secondes
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      setError(err.message || `Impossible de supprimer l'environnement ${code}.`);
    } finally {
      setLoading(false);
    }
  };
  
  const refreshData = async () => {
    await fetchEnvironments();
  };
  
  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
            <Globe className="h-6 w-6 mr-2 text-indigo-500" />
            Gestion des environnements
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            Gérez les environnements de configuration du système
          </p>
        </div>
        
        <div className="flex space-x-2">
          <Button
            variant="outline"
            icon={RefreshCw}
            onClick={refreshData}
            loading={loading}
          >
            Actualiser
          </Button>
          <Button
            variant="outline"
            icon={Copy}
            onClick={handleOpenCopyModal}
          >
            Copier la configuration
          </Button>
          <Button
            variant="primary"
            icon={PlusCircle}
            onClick={handleCreateEnv}
          >
            Nouvel environnement
          </Button>
        </div>
      </div>
      
      {/* Messages de succès/erreur */}
      {successMessage && (
        <div className="bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500 p-4 rounded">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700 dark:text-green-300">{successMessage}</p>
          </div>
        </div>
      )}
      
      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}
      
      {/* Filtres et recherche */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
        <div className="flex items-center">
          <div className="flex-1 relative">
            <input
              type="text"
              className="w-full p-2 pl-10 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Rechercher par code, nom, description..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
      
      {/* Liste des environnements */}
      <Card>
        <DataGrid
          data={filteredEnvironments}
          columns={columns}
          loading={loading}
          emptyMessage="Aucun environnement trouvé."
        />
      </Card>
      
      {/* Modal de création/édition d'environnement */}
      {showEnvModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {formMode === 'create' ? 'Nouvel environnement' : 'Modifier l\'environnement'}
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowEnvModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitEnv} className="p-6 space-y-4">
              {/* Code */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Code *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.code}
                  onChange={(e) => handleFormChange('code', e.target.value)}
                  required
                  disabled={formMode === 'edit'} // Ne pas permettre l'édition du code
                />
                {formMode === 'edit' && (
                  <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                    Le code de l'environnement ne peut pas être modifié après création.
                  </p>
                )}
              </div>
              
              {/* Nom */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nom *
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.name}
                  onChange={(e) => handleFormChange('name', e.target.value)}
                  required
                />
              </div>
              
              {/* Description */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.description || ''}
                  onChange={(e) => handleFormChange('description', e.target.value)}
                  rows={3}
                />
              </div>
              
              {/* Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Type *
                </label>
                <select
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.type}
                  onChange={(e) => handleFormChange('type', e.target.value)}
                  required
                >
                  <option value="DEVELOPMENT">Développement</option>
                  <option value="TESTING">Test</option>
                  <option value="STAGING">Pré-production</option>
                  <option value="PRODUCTION">Production</option>
                  <option value="DR">Reprise après sinistre (DR)</option>
                </select>
              </div>
              
              {/* URL */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  URL
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.url || ''}
                  onChange={(e) => handleFormChange('url', e.target.value)}
                  placeholder="https://example.com"
                />
              </div>
              
              {/* Priorité */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Priorité
                </label>
                <input
                  type="number"
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={envForm.priority || 0}
                  onChange={(e) => handleFormChange('priority', parseInt(e.target.value, 10) || 0)}
                  min="0"
                />
              </div>
              
              {/* Activé/Désactivé */}
              <div className="flex items-center">
                <input
                  id="env-active"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={envForm.isActive}
                  onChange={(e) => handleFormChange('isActive', e.target.checked)}
                />
                <label htmlFor="env-active" className="ml-2 block text-sm text-gray-900 dark:text-white">
                  Environnement actif
                </label>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowEnvModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Save}
                  loading={loading}
                >
                  {formMode === 'create' ? 'Créer' : 'Enregistrer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      
      {/* Modal de copie de configuration */}
      {showCopyModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md overflow-hidden">
            <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 px-6 py-4 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Copier la configuration
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowCopyModal(false)}
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmitCopy} className="p-6 space-y-4">
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                Cette action va copier toutes les valeurs de configuration de l'environnement source vers l'environnement cible. Les valeurs existantes dans l'environnement cible seront écrasées.
              </p>
              
              {/* Environnement source */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Environnement source *
                </label>
                <select
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={copyForm.sourceEnv}
                  onChange={(e) => handleCopyFormChange('sourceEnv', e.target.value)}
                  required
                >
                  <option value="">Sélectionner un environnement</option>
                  {environments.map(env => (
                    <option key={`source-${env.code}`} value={env.code}>
                      {env.name} ({env.code})
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Environnement cible */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Environnement cible *
                </label>
                <select
                  className="block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  value={copyForm.targetEnv}
                  onChange={(e) => handleCopyFormChange('targetEnv', e.target.value)}
                  required
                >
                  <option value="">Sélectionner un environnement</option>
                  {environments.map(env => (
                    <option 
                      key={`target-${env.code}`} 
                      value={env.code}
                      disabled={env.code === copyForm.sourceEnv}
                    >
                      {env.name} ({env.code})
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Actions */}
              <div className="flex justify-end space-x-3 border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setShowCopyModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  icon={Copy}
                  loading={loading}
                >
                  Copier
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnvironmentsManagement;